// 头部搜索导航栏
<template>
  <div class="sld_home_top_search flex_row_between_center">
    <div class="home_top_search_left flex">
      <div class="sld_home_top_search_left">
        <router-link tag="a" class="sld_logo_wrap flex_row_start_center" :to="`/index`">
          <img v-if="configInfo.main_site_logo" :src="configInfo.main_site_logo" :onerror='defaultImg' alt />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, getCurrentInstance, ref, watchEffect, onMounted, computed} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "NavLogo",
  components: {
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute()
    const L = proxy.$getCurLanguage();
    const store = useStore();
    const cartData = reactive({ data: {} }); //获取vux的store中的购物车数据
    const logoUrl = "";
    const configInfo = ref(store.state.configInfo)
    const defaultImg = ref('this.src="' + require('@/assets/common_top_logo.png') + '"')
    const keyword = ref(route.query.keyword);
    const searchUrl = require("@/assets/header/magnify.png");
    const cartUrl = require("@/assets/header/cart.png");
    const picture = require("@/assets/picture.png");
    const goods_name = "";
    const searchType = ref("product");
    const searchBarFixed = ref(false);
    const hotList = reactive({ data: [] }); //热门搜索词列表
    const tmpHotList = ref([])
    const delGoodsCartIds = ref(''); //删除商品的id
    const SAList = ref([])
    const SAShow = ref(false)
    const curLang = computed(() => store.state.languageType);
    const isGoJoin = computed(() => {
      return route.fullPath == '/join'
    })
    onMounted(() => {
      cartData.data = store.state.cartListData;
      getInitData();
    })
    const handleChangeSearchType = (type) => {
      // console.log((searchType.value != type), searchType, type)
      if (searchType.value != type) {
        searchType.value = type;
      }
    };
    const getInitData = () => {
      let names = '';
      if (curLang.value == 'zh') {
        names = 'hot_search_words';
      }else {
        names = 'hot_search_words_en';
      }
      proxy
          .$get("v3/system/front/setting/getSettings?names=" + names)
          .then(res => {
            if (res.state == 200) {
              let tmp_data = res.data[0] ? res.data[0].split(",").filter(i => i != '') : [];
              hotList.data = tmp_data;
              tmpHotList.value = tmp_data
              hotList.data = hotList.data.map(key => {
                if (key.length > 10) {
                  return key.substring(0, 10) + '...'
                } else {
                  return key
                }
              })
            }
          });
    };

    const cart_num = "";
    watchEffect(() => {
      if (route.query.keyword) {
        keyword.value = route.query.keyword;
      } else {
        keyword.value = "";
      }
      cartData.data = store.state.cartListData;
    });

    //搜索事件
    const search = () => {
      proxy.$refs.searchInput.style.color = 'rgb(153,153,153)'
      // proxy.$refs.searchInput.style.background = 'transparent'

      if (keyword.value) {
        router.push({
          path: `/goods/list`,
          query: { keyword: keyword.value }
        });
      }
    };


    const searchAssociation = (input) => {
      proxy.$get('v3/goods/front/goods/searchWords/list', {
        keyWord: input
      }).then(res => {
        if (res.state == 200) {
          SAList.value = res.data
        }
      })
    }

    const inputChange = (e) => {
      let input = e.target.value
      SAShow.value = true

      if (input) {
        searchAssociation(input)
      } else {
        SAList.value = []
      }
    }


    //热门搜索事件
    const quickSearch = (val, type) => {
      if (type == "quick") {
        router.push({ path: `/goods/list`, query: { keyword: tmpHotList.value[val] } });
      } else {
        if (val == route.query.keyword) {
          keyword.value = val
        } else {
          router.push({ path: `/goods/list`, query: { keyword: val } });

        }
      }
    };

    const inputFocus = () => {
      proxy.$refs.searchInput.style.color = '#333'
      SAShow.value = true
      if (keyword.value && SAList.value.length == 0) {
        searchAssociation(keyword.value)
      }
    }

    const inputBlur = () => {
      SAShow.value = false
    }

    return {
      L,
      logoUrl,
      searchUrl,
      cartUrl,
      picture,
      goods_name,
      hotList,
      cart_num,
      searchBarFixed,
      searchType,
      keyword,
      search,
      quickSearch,
      delGoodsCartIds,
      cartData,
      configInfo,
      defaultImg,
      inputFocus,
      tmpHotList,
      searchAssociation,
      SAList,
      inputChange,
      inputBlur,
      SAShow,
      handleChangeSearchType,
      isGoJoin
    }
  }
};
</script>

<style lang="scss" scoped>
.sld_home_top_search {
  height: 80px;
  //position: relative;
  //z-index: 12;
  width: $min-home-width;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;

  .container_header {
    width: 100%;
    position: fixed;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    border-bottom: 2px solid $colorMain;

    .sld_seach_wrap,
    .sld_cart_wrap {
      margin: 5px 40px 5px 5px;
      position: relative;
    }

    .container_header_box {
      width: 1200px;
      margin: 0 auto;

      .fixed_sld_cart_wrap {
        margin-right: 170px;

        a {
          width: 100%;
          height: 100%;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }

  .container_header {
    -webkit-animation: searchTop .5s ease-in-out;
    animation: searchTop .5s ease-in-out
  }

  @-webkit-keyframes searchTop {
    0% {
      top: -50px
    }

    to {
      top: 0
    }
  }

  @keyframes searchTop {
    0% {
      top: -50px
    }

    to {
      top: 0
    }
  }

  .sld_home_top_search_left {
    position: relative;
    display: flex;
    align-items: center;
    width: 176px;
    //margin-top: 24px;

    .sld_logo_wrap {
      display: block;
      width: 176px;
      height: 49px;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }

  .big-title {
    width: 235px;
    height: 49px;
    margin-left: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .sld_seach_wrap {
    float: left;
    width: 437px;
    padding-top: 4px;
    //margin-top: 19px;
    margin-left: 31px;
    position: relative;

    .sld_seach_box {
      width: 100%;
      z-index: 11;
      //height: 30px;
      margin-bottom: 3px;

      .search-type {
        display: flex;
        align-items: center;
        height: 16px;
        .item {
          font-size: 12px;
          font-weight: 400;
          color: $colorMain;
          line-height: 16px;
          padding: 0 8px;
          cursor: pointer;

          &.active {
            background: $colorMain;
            color: #fff;
          }

          & + .item {
            margin-left: 14px;
          }
        }
      }

      .form {
        background-color: $colorMain;
        height: 34px;
        overflow: hidden;
        border: 2px solid $colorMain;

        .text {
          background-color: #fff;
          width: 362px;
          -webkit-appearance: none;
          -webkit-border-radius: 0;
          height: 30px;
          color: rgb(153, 153, 153);
          padding: 5px 5px 5px 10px;
          background-position: 0 -360px;
          background-color: #fff;
          background-repeat: repeat-x;
          line-height: 20px;
          font-family: arial, "\5b8b\4f53";
          font-size: 12px;
          outline: none;
          border: none;
        }

        input {
          margin: 0;
          padding: 0;
          height: 34px;
          border: 0;


        }

        input::placeholder {

          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(153, 153, 153);
        }

        ::-webkit-input-placeholder {

          margin-left: 20px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(153, 153, 153);
        }

        /* 使用webkit内核的浏览器 */
        :-moz-placeholder {

          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(153, 153, 153);
        }

        /* Firefox版本19+ */
        :-ms-input-placeholder {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(153, 153, 153);
        }

        /* IE浏览器 */

        .button {
          width: 70px;
          height: 30px;
          background: $colorMain;
          font-size: 12px;
          font-weight: 600;
          color: #fff;
          //float: right;
          cursor: pointer;
          text-align: center;
        }
      }

      .hot_search_wrap {
        height: 17px;
        line-height: 17px;
        color: #B8B8B8;
        overflow: hidden;
        margin-top: 6px;

        strong {
          float: left;
          font-weight: 400;
        }

        a {
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;

          &:link,
          &:visited {
            float: left;
            margin-right: 10px;
          }

          &:hover {
            color: $colorMain;
          }
        }
      }
    }

    .search_association {
      background: #fff;
      position: absolute;
      top: 43px;
      overflow: hidden;
      position: absolute;
      left: 0;
      width: 580px;
      border: 1px solid #CCC;
      background: #fff;
      z-index: 99;

      .s_a_item {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        padding: 1px 5px;
        line-height: 24px;
        cursor: pointer;
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        color: #666;

        div:first-child {
          width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        div:last-child {
          overflow: hidden;
          color: #aaa;
        }

        &:hover {
          background-color: rgb(255, 233, 188);
        }
      }
    }
  }
  .box_search {
    flex: 1;
    margin-right: 30px;
  }
  .app_code {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 53px;
      height: 53px;
    }
    .name {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      line-height: 17px;
      margin-top: 3px;
    }
  }
}

.sld_cart_wrap {
  float: left;
  position: relative;
  z-index: 99;
  height: 34px;
  //width: 100px;
  margin-top: 20px;
  //margin-right: 13px;
  margin-left: 10px;

  dl {
    margin-bottom: 0px;

    .cart_goods_num {
      font: 12px/16px Verdana;
      //color: #fff;
      //background: $colorMain;
      text-align: center;
      display: inline-block;
      height: 16px;
      min-width: 16px;
      //border: none 0;
      //border-radius: 6px;
    }

    dt {
      position: relative;
      z-index: 3;
      width: 100%;
      height: 34px;
      padding: 0 8px;
      border: 1px solid #e3e3e3;
      background-color: #fff;
      cursor: pointer;
      font-weight: 400;
      box-sizing: border-box;

      .icon_gouwuche {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }

      &.cart_icon_text_wrap {
        a {
          color: #666666;
          font-size: 14px;
          line-height: 28px;
        }
      }
    }

    dd {
      .cart_goods {
        dl {
          padding-top: 8px;
        }

        dd {
          &.cart_goods_price {
            position: static;

            em {
              margin-right: 6px;
              width: auto;
              color: #666;

              &:nth-child(1) {
                display: block;
                font-weight: 600;
              }

              &:nth-child(2) {
                display: block;
                text-align: right;
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
  }

  dd {
    position: absolute;
    top: 33px;
    right: 0;
    width: 355px;
    border: 1px solid #e3e3e3;
    background: #fff;
    z-index: 1;
  }

  &:hover .cart_more_view {
    display: inline-block;
  }
}

.cart_more_view {
  display: none;

  .empty_cart {
    width: 100%;
    position: relative;

    .empty_cart_line {
      position: absolute;
      width: 163px;
      right: 0;
      height: 2px;
      top: -2px;
      z-index: 999;
      background: #fff;
    }

    .empty_cart_txt {
      padding: 10px;
      color: #999;
    }
  }
}

.ld {
  position: relative;
  zoom: 1;
}

.cart_data {
  height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;

  .cart_data_title {
    font-weight: 600;
    float: left;
    padding: 7px;
    line-height: 32px;
    height: 32px;
  }

  .cart_list {
    padding: 20px;
    overflow-y: scroll;

    .cart_list_pre {
      margin-bottom: 20px;

      .cart_pre_left {
        .cart_pre_img {
          width: 48px;
          height: 48px;
          border: 1px solid #e3e3e3;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .cart_pre_cen {
          width: 150px;
          margin-left: 20px;
          cursor: pointer;

          .cart_pre_name {
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .cart_pre_spec {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 5px;
          }
        }
      }

      .cart_pre_right {
        .cart_pre_price {
          color: #666;
        }

        .cart_pre_del {
          color: #666;
          cursor: pointer;
          margin-top: 10px;

          &:hover {
            color: $colorMain;
          }
        }
      }
    }
  }

  .cart_bottom {
    width: 100%;
    height: 44px;
    border: 1px solid #e3e3e3;

    .cart_bottom_left {
      padding-left: 11px;
      height: 44px;

      span {
        color: #666;

        &:nth-child(2) {
          margin-left: 5px;
        }
      }
    }

    .cart_bottom_right {
      width: 110px;
      height: 44px;
      text-align: center;
      color: #fff;
      background-color: $colorMain2;
      line-height: 44px;
      cursor: pointer;
    }
  }
}
</style>
