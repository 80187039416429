<template>
    <div class="main_store_page">
        <div class="sld_store_list">
            <img class="store_list_banner" @click="handleGoByBanner(bannerUrl.data)" v-if="curLang == 'zh'" :src="'https://image.caacmarket.com/' + bannerUrl.data.advImage" alt="" />
            <img class="store_list_banner" @click="handleGoByBanner(bannerUrl.data)" v-else :src="bannerUrl.data.advImageEn" alt="" />
            <div class="box_store_search flex_row_between_center">
                <div class="box_sort flex">
                    <div class="sort_item flex" v-for="(item, idx) in sortList" :key="idx"
                         :class="{active: activeSort.data.name == item.name}" @click="handleChangeSort(item)">
                        <div class="sort_name">{{L[item.name]}}</div>
                        <div class="sort_state" v-if="idx != 0">
                            <img v-if="activeSort.data.name == item.name" :class="{descending: item.sort == 'descending'}"
                                 src="../../assets/store/yi@2x.png" alt="" />
                            <img v-else src="../../assets/store/er@2x.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="box_right flex">
                    <div class="box_total flex">
                        {{L['共']}} <span>{{pageData.total}}</span> {{L['个结果']}}
                    </div>
                    <div class="box_page flex">
                        <img src="../../assets/store/next.png" alt="" @click="handlePrevPage" />
                        <div class="page_num flex">
                            <span class="count">{{params.current}}</span>/
                            <span class="total">{{totalPages}}</span>
                        </div>
                        <img src="../../assets/store/prev.png" alt="" @click="handleNextPage" />
                    </div>
                </div>
            </div>
            <div class="seller_list" v-loading="pageLoading" element-loading-spinner="el-icon-loading">
                <div class="fav_list fav_list_box">
                    <div v-for="(item,index) in initData.data" :key="index">
                        <StoreItem :item="item" :ItemIndex="index" :skeleton="firstLoading">
                        </StoreItem>
                    </div>

                </div>
            </div>
        </div>

        <!-- 分页 start -->
        <div class="sld_pagination">
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" v-model:currentPage="pageData.current"
                           v-model:page-size="pageData.pageSize" background layout="prev, pager, next"
                           :total="pageData.total" :hide-on-single-page="false">
            </el-pagination>
        </div>
        <!-- 分页 end -->

        <!-- 空页面 start-->
        <SldCommonEmpty v-if="!firstLoading&&pageLoading&&!initData.data.length" />
        <!-- 空页面 end-->
    </div>
</template>

<script>
import {getCurrentInstance, onMounted, reactive, ref, computed, watch, watchEffect} from 'vue';
    import StoreItem from './StoreItem';
    import SldCommonEmpty from "../../components/SldCommonEmpty";
    import {useStore} from "vuex";
    import {useRoute} from "vue-router";

    export default {
        components: {
            StoreItem,
            SldCommonEmpty,
        },
        setup() {
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage()
            const route = useRoute()
            const firstLoading = ref(true); //是否第一次加载
            const initData = reactive({ data: [] })
            const bannerUrl = reactive({ data: {} });
            const activeSort = reactive({ data: {name: '默认'} }); // 当前排序
            const pageLoading = ref(false);
            const store = useStore();
            const curLang = computed(() => store.state.languageType);
            const totalPages = computed(() => {
                if (pageData.total % pageData.pageSize == 0 && pageData.total > pageData.pageSize) {
                    return parseInt(pageData.total / pageData.pageSize)
                }else {
                    return parseInt(pageData.total / pageData.pageSize) + 1
                }
            });
            const pageData = reactive({
                current: 1,
                pageSize: 10,
                total: null,
            })
            // 排序 销量salesAsc/salesDesc 人气lookAsc/lookDesc 入驻时间 createTimeAsc/createTimeDesc 综合评分scoreAsc/scoreDesc
            const sortList = reactive([
                {
                    name: '默认'
                },{
                    name: '销量排序',
                    key: 'sales',
                    sort: 'descending'
                },{
                    name: '人气排序',
                    key: 'look',
                    sort: 'descending'
                },{
                    name: '入驻时间',
                    key: 'createTime',
                    sort: 'descending'
                },{
                    name: '综合评分',
                    key: 'score',
                    sort: 'descending'
                }
            ])
            const chooseIndustry = ref('')

            const params = reactive({
                current: pageData.current,
                pageSize: pageData.pageSize,
                keyword: route.query.keyword
            })
            const indexNum = ref(0)

            watch(()=>activeSort,
                () => {
                    sortList.map(item => {
                        if (item.name != '默认' && item.name != activeSort.data.name) {
                            item.sort = 'descending'
                        }
                    })
                },
                //深度监听
                {deep: true}
            );
            //初始化数据
            const getInitData = (params) => {
                proxy.$get('/v3/seller/front/store/list', params).then(res => {
                    firstLoading.value = false;
                    pageLoading.value = false;
                    // proxy.$loadingInstance.close();
                    // console.log('getInitData', res.data)
                    if (res.state === 200) {
                        // res.data.list.map(item => {
                        //     item.newGoodsListVOS = [...item.newGoodsListVOS, ...item.goodsListVOList]
                        // })
                        initData.data = res.data.list
                        pageData.current = res.data.pagination.current
                        pageData.pageSize = res.data.pagination.pageSize
                        pageData.total = res.data.pagination.total
                    }
                })
            }
            //监听事件--start
            watchEffect(() => {
                if (route.query.keyword != undefined) {
                    params.keyword = route.query.keyword;
                    getInitData(params)
                }
            });
            const handleGoByBanner = (item) => {
                proxy.$diyNavGo(JSON.parse(item.advUrl.replace(/&quot;/g,"\"")));
            }
            //切换排序
            const handleChangeSort = (item) => {
                if (activeSort.data.name != item.name) {
                    activeSort.data = {...item}
                }else {
                    // console.log(item.hasOwnProperty('sort'))
                    if (item.hasOwnProperty('sort')) {
                        if (item.sort != 'descending') {
                            item.sort = 'descending'
                        }else {
                            item.sort = 'ascending'
                        }
                    }
                    activeSort.data = {...item}
                }
                console.log(activeSort.data)
                if (activeSort.data.sort == 'descending') {
                    if (activeSort.data.key == 'sales') {
                        params.orderBy = 'salesDesc';
                    }else if (activeSort.data.key == 'look') {
                        params.orderBy = 'lookDesc';
                    }else if (activeSort.data.key == 'createTime') {
                        params.orderBy = 'createTimeDesc';
                    }else if (activeSort.data.key == 'score') {
                        params.orderBy = 'scoreDesc';
                    }
                }else if(activeSort.data.sort == 'ascending') {
                    if (activeSort.data.key == 'sales') {
                        params.orderBy = 'salesAsc';
                    }else if (activeSort.data.key == 'look') {
                        params.orderBy = 'lookAsc';
                    }else if (activeSort.data.key == 'createTime') {
                        params.orderBy = 'createTimeAsc';
                    }else if (activeSort.data.key == 'score') {
                        params.orderBy = 'scoreAsc';
                    }
                }else {
                    params.orderBy = 'scoreDesc';
                }
                getInitData(params)
            }
            //分页切换
            const handleChooseIndustry = (item) => {
                if (chooseIndustry.value != item.key) {
                    chooseIndustry.value = item.key;
                }
            }
            const filterSort = (index) => { //根据三种分类进行请求传参
                indexNum.value = index;
                params.sort = index
                getInitData(params)

            }
            const getBanner = () => {
                let param = {
                    advPlaceId: '8',
                    pageSize: 1
                }
                proxy.$get("v3/system/front/adv/list", param).then(res => {
                    firstLoading.value = false;
                    if (res.state == 200) {
                        // console.log('getIndexTopBanner', res.data)
                        bannerUrl.data = res.data.list[0] || {};
                    }
                });
            };

            //上一页
            const handlePrevPage = () => {
                if (params.current > 1) {
                    params.current--;
                    getInitData(params)
                }
            }

            //下一页
            const handleNextPage = () => {
                if (params.current < totalPages.value) {
                    params.current++;
                    getInitData(params)
                }
            }

            //分页切换
            const handlePrevCilickChange = (e) => {
                params.current = e
                getInitData(params)
            }
            const handleNextCilickChange = (e) => {
                params.current = e
                getInitData(params)
            }
            const handleCurrentChange = (e) => {
                params.current = e
                getInitData(params)
            }
            const handleSizeChange = (e) => {
                params.pageSize = e
                getInitData(params)
            }

            //店铺搜索
            const keyWord = ref('')
            const search_store = (keyWord) => {
                params.keyword = keyWord
                getInitData(params)
            }
            onMounted(() => {
                getInitData(params)
                getBanner();
                pageLoading.value = true;
            })
            return {
                initData,
                filterSort,
                indexNum,
                pageData,
                activeSort,
                sortList,
                chooseIndustry,
                bannerUrl,
                handleChangeSort,
                handleChooseIndustry,
                handlePrevCilickChange,
                handleNextCilickChange,
                handleCurrentChange,
                handleSizeChange,
                L,
                keyWord,
                curLang,
                search_store,
                firstLoading,
                pageLoading,
                totalPages,
                params,
                handlePrevPage,
                handleNextPage,
                handleGoByBanner,
            }
        }

    }
</script>

<style lang="scss" scoped>
    @import "../../style/storeList.scss";
    @import "../../style/base.scss";

    .sld_pagination {
        width: $min-home-width;
        padding: 30px 0;
      margin: 0 auto;
    }
</style>
<style lang="scss">
.sld_pagination {
    .el-pagination {
        text-align: right;
    }
}
</style>
