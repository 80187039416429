<template>
<!-- 店铺自定义装修  -->
<div class="store_diy">
  <div class="" v-for="(item,index) in decorateData.data" v-bind:key="'de'+index">
    <!-- 楼层标题 start-->
    <div class="floor_wrap" v-if="item.type === 'floor_title'">
      <div class="fl-tit"
          :style="{
        'margin-top': item.marginTop + 'px',
        'margin-bottom': item.marginBottom + 'px',
        'background-color': item.bgColor,}">
        <span class="vm bigw mr20" :style="{'color': item.titleColor,'font-size': item.titleFontSize + 'px',}">{{ item.title }}</span>
        <span class="vm" :style="{'color': item.subTitleColor,'font-size': item.subTitleFontSize + 'px',}">{{ item.subTitle }}</span>
        <span class="vm right cursor" v-if="item.showMore" :style="{'color': item.lookmoreColor,}" @click="diyNavTo({link_type:item.path.type,path:item.path.link})">{{L['查看更多']}}&nbsp;<i class="el-icon-arrow-right"></i></span>
      </div>
    </div>
    <!-- 楼层标题 end-->

    <!-- 商城招牌 start-->
    <div class="shop-sign_wrap" v-if="item.type === 'shop_signboard'">
      <div class="sign-com" :style="{'background-image': 'urL['+item.bgImgUrl+')',}">
        <div class="sign-top">
          <div class="top-logo" ><div v-if="item.showLogo">
            <!--<el-image style="width: 100%; height: 100%" :src="item.storeLogoUrl" fit="contain"></el-image>-->
          </div></div>
          <div class="top-search" v-if="item.showSearch">
            <div class="search-input">
              <el-input :placeholder="L['请输入商品名或店铺名']" v-model="searchWords" style="width: 500px;">
                <template #suffix><i class="el-icon-sort el-input__icon"></i></template>
                <template #append>
                  <el-button type="primary">{{L['搜索']}}</el-button>
                </template>
              </el-input>
            </div>
            <div class="top-cart" @click="diyNavTo({link_type:'4',path:'/cart/index'})"><i class="el-icon-shopping-cart-full"></i><span>{{L['购物车']}}(0)</span></div>
          </div>
        </div>
        <div class="sign-bottom" v-if="item.showNav">
          <div class="all-cat">{{L['全部商品分类']}} &nbsp;&nbsp;<i class="el-icon-s-unfold"></i></div>
          <div class="catList">
            <div class="catcell" v-for="(em,i) in item.navList" :key="'c'+i" @click="diyNavTo({link_type:em.type,path:em.path})">{{em.navName}}</div>
          </div>
        </div>
      </div>

    </div>
    <!-- 商城招牌 end-->

    <!-- 图片/轮播 start-->
    <div class="img-carousel_wrap" v-if="item.type === 'img_carousel'">
      <div class="mta" :style="{width:item.showType ==='1920'?'100%':'1300px'}">
        <el-carousel trigger="click" :height="item.height+'px'">
          <el-carousel-item v-for="(em,i) in item.imgList" :key="'img'+i" @click="diyNavTo({link_type:em.type,path:em.path})">
            <el-image style="width: 100%; height: 100%" :src="em.imgUrl" fit="cover"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 图片/轮播 end-->

    <!-- 商品列表 start-->
    <div class="goods_list_wrap compon" v-if="item.type === 'goods_list'">
      <div class="compon-in" >
        <div class="goods-row" v-if="item.goodsList.length === 0">
          <div class="goods-col" :class="item.listSty===3?'width33':item.listSty===4?'width25':'width20'"
               v-for="em in item.listSty" :key="em">
            <div class="goods-item">
              <div class="goods-img"><i class="el-icon-picture"></i></div>
              <div class="goods-det">
                <p>
                  <span v-if="item.contentList.includes(1)">{{L['商品名称']}}</span>&nbsp;<span v-if="item.contentList.includes(2)">{{L['商品买点']}}</span>
                </p>
                <p class="price" v-if="item.contentList.includes(3)">{{L['价格']}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="goods-row" v-else>
          <div class="goods-col" :class="item.listSty===3?'width33':item.listSty===4?'width25':'width20'"
               v-for="(em,i) in item.goodsList" :key="i" >
            <div class="goods-item" @click="goGoodsDetail(em)">
              <div class="goods-img">
                <el-image style="width: 100%; height: 100%" :src="em.mainImage" fit="cover" ></el-image>
              </div>
              <div class="goods-det">
                <p class="goods-name">
                  <span v-if="item.contentList.includes(1)">{{ em.goodsName }}</span>&nbsp;<span v-if="item.contentList.includes(2)">{{ em.goodsBrief }}</span>
                </p>
                <p class="price" v-if="item.contentList.includes(3)">
                  <span> ￥{{ Number(em.goodsPrice).toFixed(2) }}</span>
                  <span>销量：{{em.actualSales.toString().length>5?parseInt(em.actualSales/10000)+'w':em.actualSales}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 商品列表 end-->

    <!-- 辅助间隔 start-->
    <div class="gap_wrap" v-if="item.type === 'support_gap'" :style="{ height: item.height + 'px', background: item.background }"></div>
    <!-- 辅助间隔 end-->

    <!-- 限时秒杀 start-->
    <div class="timed_seckill_wrap compon" v-if="item.type === 'timed_seckill'"
         :style="{
          'margin-top': item.marginTop + 'px',
          'margin-bottom': item.marginBottom + 'px',
          'background-color': item.bgColor,}">
      <div class="compon-tit" >
        <span class="vm bigw mr20" :style="{color: item.titleColor,'font-size': item.titleFontSize + 'px' }">{{ item.title }}</span>
        <span v-if="item.showSubTitle" class="vm" :style="{color: item.subTitleColor,'font-size': item.subTitleFontSize + 'px'}">{{ item.subTitle }}</span>
        <span class="vm right cursor" v-if="item.showMore" @click='diyNavTo({link_type:item.path.type,path:item.path.link})'>{{L['查看更多']}}&nbsp;<i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="compon-in">
        <el-row :gutter="20" v-if="item.goodsList.length === 0">
          <el-col :span="6" v-for="em in 4" :key="em">
            <div class="goods-col">
              <div class="goods-img"><i class="el-icon-picture"></i></div>
              <div class="goods-det">
                <p class="goods-name">
                  <span v-if="item.contentList.includes(1)">{{L['商品名称']}}</span>&nbsp;<span v-if="item.contentList.includes(2)">{{L['商品买点']}}</span>
                </p>
                <p class="price" v-if="item.contentList.includes(3)">{{L['价格']}}</p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-else>
          <el-col :span="6" v-for="(em, i) in item.goodsList" :key="i">
            <div class="goods-col" @click="goGoodsDetail(em)">
              <div class="goods-img">
                <el-image style="width: 100%; height: 100%" :src="em.mainImage" fit="cover" ></el-image>
              </div>
              <div class="goods-det">
                <p class="goods-name">
                  <span v-if="item.contentList.includes(1)">{{ em.goodsName }}</span>&nbsp;<span v-if="item.contentList.includes(2)">{{ em.goodsBrief }}</span>
                </p>
                <p class="price" v-if="item.contentList.includes(3)">
                  <span> ￥{{ Number(em.goodsPrice).toFixed(2) }}</span>
                  <span>销量：{{em.actualSales.toString().length>5?parseInt(em.actualSales/10000)+'w':em.actualSales}}</span></p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <!-- 限时秒杀 end-->

    <!-- 优惠团购 start-->
    <div class="group_purchase_wrap compon" v-if="item.type === 'group_purchase'"
        :style="{
        'margin-top': item.marginTop + 'px',
        'margin-bottom': item.marginBottom + 'px',
        'background-color': item.bgColor,}">
      <div class="compon-tit">
        <span class="vm bigw mr20" :style="{'color': item.titleColor,'font-size': item.titleFontSize + 'px',}">{{ item.title }}</span>
        <span v-if="item.showSubTitle" class="vm" :style="{'color': item.subTitleColor,'font-size': item.subTitleFontSize + 'px',}">{{ item.subTitle }}</span>
        <span class="vm right cursor" v-if="item.showMore" @click='diyNavTo({link_type:item.path.type,path:item.path.link})'>{{L['查看更多']}}&nbsp;<i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="compon-in">
        <el-row :gutter="20" v-if="item.goodsList.length === 0">
          <el-col :span="6" v-for="em in 4" :key="em">
            <div class="goods-col">
              <div class="goods-img"><i class="el-icon-picture"></i></div>
              <div class="goods-det">
                <p class="goods-name">
                  <span v-if="item.contentList.includes(1)">{{L['商品名称']}}</span>&nbsp;<span v-if="item.contentList.includes(2)">{{L['商品买点']}}</span>
                </p>
                <p class="price" v-if="item.contentList.includes(3)">{{L['价格']}}</p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-else>
          <el-col :span="6" v-for="(em, i) in item.goodsList" :key="i">
            <div class="goods-col" @click='goGoodsDetail(em)'>
              <div class="goods-img">
                <el-image style="width: 100%; height: 100%" :src="em.mainImage" fit="cover"></el-image>
              </div>
              <div class="goods-det">
                <p class="goods-name">
                  <span v-if="item.contentList.includes(1)">{{ em.goodsName }}</span>&nbsp;<span v-if="item.contentList.includes(2)">{{ em.goodsBrief }}</span>
                </p>
                <p class="price" v-if="item.contentList.includes(3)">
                  <span> ￥{{ Number(em.goodsPrice).toFixed(2) }}</span>
                  <span>销量：{{em.actualSales.toString().length>5?parseInt(em.actualSales/10000)+'w':em.actualSales}}</span>
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 优惠团购 end-->

    <!-- 商品模块1 start-->
    <div class="goods_module1_wrap compon" v-if="item.type === 'goods_module1'">
      <div class="compon-in">
        <el-row :gutter="20">
          <el-col :span="8" v-for="(liem, i) in item.styleList" :key="i">
            <div class="goods-em">
              <div class="goods-tit" @click='diyNavTo({link_type:liem.path.type,path:liem.path.link})'>
                <p>{{ liem.title }} <i class="el-icon-arrow-right"></i></p>
                <p class="subTitle">{{ liem.subTitle }}</p>
              </div>
              <div v-if="liem.goodsList.length === 0">
                <div class="goods-col" v-for="j in 3" :key="j + '1'">
                  <div class="goods-img"><i class="el-icon-picture"></i></div>
                  <div class="goods-det">
                    <p class="goods-name"><span>{{L['商品名称']}}</span>&nbsp;<span>{{L['商品卖点']}}</span></p>
                    <p class="price">￥{{L['价格']}}<span style="text-decoration: line-through">0.00</span></p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="goods-col" v-for="(em, j) in liem.goodsList" :key="'goods'+j" @click='goGoodsDetail(em)'>
                  <div class="goods-img">
                    <el-image style="width: 100%; height: 100%" :src="em.mainImage" fit="cover"></el-image>
                  </div>
                  <div class="goods-det">
                    <p class="goods-name"><span>{{ em.goodsName }}</span>&nbsp;<span>{{ em.goodsBrief }}</span></p>
                    <div class="price">
                      <span>
                        ￥{{L['价格']}}{{ em.goodsPrice }}
                        <span style="text-decoration: line-through;color: #999999; margin-left: 10px;">{{ em.marketPrice }}</span>
                      </span>
                      <span>{{L['销量']}}：{{em.actualSales.toString().length>5?parseInt(em.actualSales/10000)+'w':em.actualSales}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 商品模块1 end-->

    <!-- 商品模块2 start-->
    <div class="goods_module2_wrap compon" v-if="item.type === 'goods_module2'">
      <div class="compon-in" >
        <div class="goods-tit" @click='diyNavTo({link_type:item.path.type,path:item.path.link})'>
          <p>{{ item.title }} <i class="el-icon-arrow-right"></i></p>
        </div>
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="goods-left-em" v-if="item.maingoodsList.length === 0">
              <div class="goods-left-col">
                <div class="goods-img"><i class="el-icon-picture"></i></div>
                <div class="goods-det">
                  <p class="goods-name"><span>{{L['商品名称']}}</span>&nbsp;<span>{{L['商品卖点']}}</span></p>
                  <p class="price">￥{{L['价格']}}<span style="text-decoration: line-through">0.00</span></p>
                </div>
              </div>
            </div>
            <div class="goods-left-em" v-else>
              <div class="goods-left-col" v-for="(em, i) in item.maingoodsList" :key="i" @click='goGoodsDetail(em)'>
                <div class="goods-img">
                  <el-image style="width: 100%; height: 100%" :src="em.mainImage" fit="cover"></el-image>
                </div>
                <div class="goods-det">
                  <p class="goods-name"><span>{{ em.goodsName }}</span>&nbsp;<span>{{ em.goodsBrief }}</span></p>
                  <div class="price">
                      <span>
                        ￥{{L['价格']}}{{ em.goodsPrice }}
                        <span style="text-decoration: line-through;color: #999999; margin-left: 10px;">{{ em.marketPrice }}</span>
                      </span>
                    <span>销量：{{em.actualSales.toString().length>5?parseInt(em.actualSales/10000)+'w':em.actualSales}}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="20">
            <div v-if="item.goodsList.length === 0">
              <el-col :span="8" v-for="i in 6" :key="i">
                <div class="goods-col">
                  <div class="goods-img"><i class="el-icon-picture"></i></div>
                  <div class="goods-det">
                    <p class="goods-name"><span>{{L['商品名称']}}</span>&nbsp;<span>{{L['商品卖点']}}</span></p>
                    <p class="price">￥{{L['价格']}}<span style="text-decoration: line-through">0.00</span></p>
                  </div>
                </div>
              </el-col>
            </div>
            <div v-else>
              <el-col :span="8" v-for="(em, i) in item.goodsList" :key="i">
                <div class="goods-col" @click='goGoodsDetail(em)'>
                  <div class="goods-img">
                    <el-image style="width: 100%; height: 100%" :src="em.mainImage" fit="cover"></el-image>
                  </div>
                  <div class="goods-det">
                    <p class="goods-name"><span>{{ em.goodsName }}</span>&nbsp;<span>{{ em.goodsBrief }}</span></p>
                    <div class="price">
                      <span>
                        ￥{{L['价格']}}{{ em.goodsPrice }}
                        <span style="text-decoration: line-through;color: #999999; margin-left: 10px;">{{ em.marketPrice }}</span>
                      </span>
                      <span>销量：{{em.actualSales.toString().length>5?parseInt(em.actualSales/10000)+'w':em.actualSales}}</span>
                    </div>
                  </div>
                </div>
              </el-col>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <!-- 商品模块2 end-->

    <!-- 商品模块3 start-->
    <div class="goods_module3_wrap compon" v-if="item.type === 'goods_module3'">
      <div class="compon-in" >
        <el-row :gutter="20">
          <el-col :span="12" v-for="(liem, i) in item.styleList" :key="i">
            <div class="goods-em">
              <div class="goods-tit" @click='diyNavTo({link_type:liem.path.type,path:liem.path.link})'>
                <p>{{ liem.title }} <i class="el-icon-arrow-right"></i></p>
              </div>
              <div v-if="liem.goodsList.length === 0">
                <div class="goods-col" v-for="j in 3" :key="j + '1'">
                  <div class="goods-img"><i class="el-icon-picture"></i></div>
                  <div class="goods-det">
                    <p class="goods-name"><span>{{L['商品名称']}}</span>&nbsp;<span>{{L['商品卖点']}}</span></p>
                    <p class="price">￥{{L['价格']}}<span style="text-decoration: line-through">0.00</span></p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="goods-col" v-for="(em, j) in liem.goodsList" :key="'goods'+j" @click='goGoodsDetail(em)'>
                  <div class="goods-img">
                    <el-image style="width: 100%; height: 100%" :src="em.mainImage" fit="cover"></el-image>
                  </div>
                  <div class="goods-det">
                    <p class="goods-name"><span>{{ em.goodsName }}</span>&nbsp;<span>{{ em.goodsBrief }}</span></p>
                    <div class="price">
                      <span>
                        ￥{{L['价格']}}{{ em.goodsPrice }}
                        <span style="text-decoration: line-through;color: #999999; margin-left: 10px;">{{ em.marketPrice }}</span>
                      </span>
                      <span>销量：{{em.actualSales.toString().length>5?parseInt(em.actualSales/10000)+'w':em.actualSales}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <!-- 商品模块3 end-->

    <!-- 商品模块4 start-->
    <div class="goods_module4_wrap compon" v-if="item.type === 'goods_module4'">
      <div class="compon-in">
        <div class="poster-img" v-if="!item.mainImg"><i class="el-icon-picture"></i></div>
        <div class="poster-img" v-if="item.mainImg" @click='diyNavTo({link_type:item.imgLink.type,path:item.imgLink.link})'>
          <el-image style="width: 100%; height: 100%" :src="item.mainImg" fit="cover"></el-image>
        </div>
        <el-row :gutter="20" class="padding" v-if="item.goodsList.length===0">
          <el-col :span="6" v-for="i in 4" :key="i">
            <div class="goods-col">
              <div class="goods-img"><i class="el-icon-picture"></i></div>
              <div class="goods-det">
                <p class="goods-name"><span>{{L['商品名称']}}</span>&nbsp;<span>{{L['商品卖点']}}</span></p>
                <p class="price">￥{{L['价格']}}<span style="text-decoration: line-through">0.00</span></p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="padding" v-else>
          <el-col :span="6" v-for="(em,i) in item.goodsList" :key="i" >
            <div class="goods-col" @click='goGoodsDetail(em)'>
              <div class="goods-img">
                <el-image style="width: 100%; height: 100%" :src="em.mainImage" fit="cover"></el-image>
              </div>
              <div class="goods-det">
                <p class="goods-name"><span>{{ em.goodsName }}</span>&nbsp;<span>{{ em.goodsBrief }}</span></p>
                <div class="price">
                      <span>
                        ￥{{L['价格']}}{{ em.goodsPrice }}
                        <span style="text-decoration: line-through;color: #999999; margin-left: 10px;">{{ em.marketPrice }}</span>
                      </span>
                  <span>销量：{{em.actualSales.toString().length>5?parseInt(em.actualSales/10000)+'w':em.actualSales}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <!-- 商品模块4 end-->

    <!-- 商品模块5 start-->
    <div class="goods_module5_wrap compon" v-if="item.type === 'goods_module5'">
      <div class="compon-in">
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="poster-img" v-if="!item.mainImg"><i class="el-icon-picture"></i></div>
            <div class="poster-img" v-if="item.mainImg" @click='diyNavTo({link_type:item.imgLink.type,path:item.imgLink.link})'>
              <el-image style="width: 100%; height: 100%" :src="item.mainImg" fit="cover"></el-image>
            </div>
          </el-col>
          <el-col :span="19">
            <el-row :gutter="20" v-if="item.goodsList.length === 0">
              <el-col :span="6" v-for="i in 4" :key="i">
                <div class="goods-col">
                  <div class="goods-img"><i class="el-icon-picture"></i></div>
                  <div class="goods-det">
                    <p class="goods-name"><span>{{L['商品名称']}}</span>&nbsp;<span>{{L['商品卖点']}}</span></p>
                    <p class="price">￥{{L['价格']}}<span style="text-decoration: line-through">0.00</span></p>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-else>
              <el-col :span="6" v-for="(em,i) in item.goodsList" :key="i" >
                <div class="goods-col" @click='goGoodsDetail(em)'>
                  <div class="goods-img">
                    <el-image style="width: 100%; height: 100%" :src="em.mainImage" fit="cover"></el-image>
                  </div>
                  <div class="goods-det">
                    <p class="goods-name"><span>{{ em.goodsName }}</span>&nbsp;<span>{{ em.goodsBrief }}</span></p>
                    <div class="price">
                      <span>
                        ￥{{L['价格']}}{{ em.goodsPrice }}
                        <span style="text-decoration: line-through;color: #999999; margin-left: 10px;">{{ em.marketPrice }}</span>
                      </span>
                      <span>销量：{{em.actualSales.toString().length>5?parseInt(em.actualSales/10000)+'w':em.actualSales}}</span>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 商品模块5 end-->
  </div>

</div>
</template>

<script>
import {ref, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
export default {
  name: "StoreDiy",
  props: {
    decorateData: Object,//装修的数据
    source: String,//来源，home:平台首页  store:店铺装修
  },
  setup(props, { emit }) {
    const searchWords = ref()
    const route = useRoute()
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    /*
    * diyNavTo 链接跳转
    * val={
    * link_type：1，// 链接跳转分类 1:商品 2：分类 3：店铺 4：站内页面 5：自定义跳转
    * path:'',//选中的值
    * }
    *
    * */
    const diyNavTo = (val) => {
      if (route.query.vid) {
        val.storeId = route.query.vid
      }
      proxy.$diyNavTo(val)
    }
    const goGoodsDetail = (val) => {
      proxy.$goGoodsDetail(val.productId)
    }
    const goGoodsListByCatId = (val) => {
      proxy.$goGoodsListByCatId(val)
    }
    //adv_19楼层tab切换事件
    const adv19Tab = (dataId, index, key) => {
      emit("adv19", { dataId: dataId, left_or_right_index: index, tab_index: key });
    }
    return { L,searchWords, goGoodsDetail, diyNavTo, adv19Tab, goGoodsListByCatId };
  },
};
</script>

<style lang="scss" scoped>

.floor_wrap {
  width: 100%;
  //height: 80px;
  background: transparent;
  .fl-tit {
    width: 1300px;
    margin: 0 auto;
    line-height: 80px;
    color: #999;
    .bigw {
      font-size: 28px;
      color: #333;
    }
  }
}
.shop-sign_wrap {
  width: 100%;
  background: #fff;

  .sign-com {
    color: #999;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    .sign-top {
      width: 1300px;
      height: 130px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      // max-width: 1200px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding-bottom: 26px;
      .top-logo{
        width: 190px;
        height: 52px;
        box-sizing: border-box;
        //border: 1px solid #eeeeee;
        text-align: center;
        line-height: 50px;
        .el-icon-picture{
          font-size: 50px;
          color: #eee;
        }
      }
    }
    .top-search{
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .top-cart{
      width: 160px;
      height: 40px;
      background: #e1251b;
      display: -webkit-box;
      display: -ms-flexbox;
      color: #fff;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: relative;
      margin-left: 30px;
    }
    .sign-bottom{
      display: flex;
      width: 1300px;
      margin: 0 auto;
      .all-cat{
        width: 160px;
        height: 40px;
        line-height: 40px;
        background: #e1251b;
        color: #ffffff;
        text-align: center;
      }
      .catList{
        flex: 1;
        .catcell{
          display: inline-block;
          vertical-align: middle;
          padding: 5px 20px;
          line-height: 30px;
          cursor: pointer;
          max-width: 180px;
          box-sizing: border-box;
        }
      }
    }
  }
}
.img-carousel_wrap {
  width: 100%;
  background: #fff;
  .imgplaceholder {
    color: #999;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mta{
    margin: 0 auto;
  }
}
.gap_wrap {
  width: 100%;
  height: 10px;
  background: transparent;
}
.compon {
  width: 100%;
  background: transparent;
  .compon-tit {
    width: 1300px;
    margin: 0 auto;
    line-height: 80px;
    color: #999;
    .bigw {
      font-size: 28px;
      color: #333;
    }
  }
  .compon-in {
    width: 1300px;
    margin: 0 auto;
    padding: 10px 0;
    //background: #FFFFFF;
  }
}
.goods_list_wrap{
  .goods-row{}
  .goods-col {
    display: inline-block;
    vertical-align: top;
    width: 33.3%;
    padding: 10px;
    box-sizing: border-box;
    .goods-item{
      background: #fff;
    }
    .goods-img {
      height: 200px;
      background: #fafbfc;
      text-align: center;
      line-height: 300px;
      i {
        font-size: 80px;
        color: #ddd;
      }
    }
    .goods-det {
      font-size: 14px;
      text-align: center;
      padding: 10px;
      .goods-name {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 40px;
      }
      .price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: #999999;
        font-size: 14px;
        span:first-child{
          color: #e1251b;
        }
      }
    }
  }
  .width33{
    width: 33.3%;
  }
  .width25{
    width: 25%;
  }
  .width20{
    width: 20%;
  }
  .grid-con {
    // width: 20px;
    height: 50px;
    margin-bottom: 10px;
    background: #f3f4f5;
  }
}

.timed_seckill_wrap,.group_purchase_wrap{
  .goods-col {
    background: #fff;
    // padding: 10px;
    margin-bottom: 20px;
    .goods-img {
      height: 300px;
      background: #fafbfc;
      text-align: center;
      line-height: 300px;
      i {
        font-size: 80px;
        color: #ddd;
      }
    }
    .goods-det {
      font-size: 14px;
      text-align: center;
      padding: 10px;
      p {
        line-height: 20px;
      }
      p:first-child{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 40px;
      }
      .price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: #999999;
        font-size: 14px;
        span:first-child{
          color: #e1251b;
        }
      }
    }
  }
  .shop-list {
    padding: 10px 15px;
    border: 1px dotted #409eff;
    display: flex;
    margin-top: 10px;
    .list-em {
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px solid #ddd;
      cursor: pointer;
      margin-right: 10px;
    }
    .goshover {
      position: relative;
      .el-icon-error {
        position: absolute;
        top: -5px;
        right: -5px;
        color: #155bd4;
        cursor: pointer;
      }
    }
  }
}

.goods_module1_wrap{
  .goods-em {
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    height: 517px;
    .goods-tit {
      font-size: 24px;
      i {
        display: inline-block;
        padding: 2px;
        border: 1px solid #e1251b;
        color: #e1251b;
        border-radius: 50%;
        font-size: 18px;
        cursor: pointer;
        margin-left: 10px;
      }
      .subTitle {
        font-size: 14px;
        color: #999;
      }
    }
  }
  .goods-col {
    margin-top: 20px;
    .goods-img {
      display: inline-block;
      vertical-align: middle;
      background: #fafbfc;
      text-align: center;
      width: 120px;
      height: 120px;
      line-height: 120px;
      i {
        font-size: 60px;
        color: #eee;
        margin-top: 30px;
      }
    }
    .goods-det {
      display: inline-block;
      height: 120px;
      vertical-align: middle;
      font-size: 14px;
      color: #666;
      padding: 10px;
      position: relative;
      box-sizing: border-box;
      width: calc(100% - 120px);
      p {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
      .price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: #999999;
        font-size: 14px;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        span:first-child{
          color: #e1251b;
        }
        span:last-child{
          float: right;
        }
      }
    }
  }
  .shop-list {
    padding: 10px 15px;
    border: 1px dotted #409eff;
    display: flex;
    margin-top: 10px;
    .list-em {
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px solid #ddd;
      cursor: pointer;
      margin-right: 10px;
    }
    .goshover {
      position: relative;
      .el-icon-error {
        position: absolute;
        top: -5px;
        right: -5px;
        color: #155bd4;
        cursor: pointer;
      }
    }
  }
  .grid-con {
    // width: 20px;
    height: 50px;
    margin-bottom: 10px;
    background: #f3f4f5;
  }
}

.goods_module2_wrap{
  .compon-in{
    background: #FFFFFF;
  }
  .goods-tit {
    font-size: 24px;
    i {
      display: inline-block;
      padding: 2px;
      border: 1px solid #e1251b;
      color: #e1251b;
      border-radius: 50%;
      font-size: 18px;
      cursor: pointer;
      margin-left: 10px;
    }
    .subTitle {
      font-size: 14px;
      color: #999;
    }
  }
  .goods-em {
    padding: 20px;
    box-sizing: border-box;
  }
  .goods-left-em {
    margin-top: 20px;
  }
  .goods-left-col {
    .goods-img {
      background: #fafbfc;
      text-align: center;
      // width: 120px;
      height: 200px;
      line-height: 120px;
      i {
        font-size: 40px;
        color: #eee;
        margin-top: 60px;
      }
    }
    .goods-det {
      font-size: 14px;
      padding: 10px;
      position: relative;
      text-align: center;
      p {
        line-height: 20px;
      }
      .price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: #999999;
        font-size: 14px;
        span:first-child{
          color: #e1251b;
        }
      }
    }
  }
  .goods-col {
    // display: flex;
    margin-top: 20px;
    .goods-img {
      display: inline-block;
      vertical-align: middle;
      background: #fafbfc;
      text-align: center;
      width: 120px;
      height: 120px;
      line-height: 120px;
      i {
        font-size: 60px;
        color: #eee;
        margin-top: 30px;
      }
    }
    .goods-det {
      display: inline-block;
      vertical-align: middle;
      height: 120px;
      font-size: 14px;
      padding: 10px;
      position: relative;
      box-sizing: border-box;
      width: calc(100% - 120px);
      word-break: break-all;
      p {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        word-break: break-all;
      }
      .price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: #999999;
        font-size: 14px;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        span:first-child{
          color: #e1251b;
        }
        span:last-child{
          float: right;
        }
      }
    }
  }
}

.goods_module3_wrap{
  .goods-em {
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    height: 338px;
    .goods-tit {
      font-size: 24px;
      i {
        display: inline-block;
        padding: 2px;
        border: 1px solid #e1251b;
        color: #e1251b;
        border-radius: 50%;
        font-size: 18px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .goods-col {
    margin-top: 20px;
    display: inline-block;
    vertical-align: top;
    width: 33%;
    box-sizing: border-box;
    padding: 5px;
    text-align: center;
    .goods-img {
      background: #fafbfc;
      text-align: center;
      height: 140px;
      line-height: 120px;
      i {
        font-size: 60px;
        color: #eee;
        margin-top: 30px;
      }
    }
    .goods-det {
      font-size: 14px;
      text-align: center;
      padding: 10px;
      position: relative;
      p {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 40px;
      }
      .price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: #999999;
        font-size: 14px;
        span:first-child{
          color: #e1251b;
        }
      }
    }
  }
}

.goods_module4_wrap{
  .poster-img {
    height: 300px;
    background: #fafbfc;
    text-align: center;
    line-height: 300px;
    margin-bottom: 10px;
    i {
      font-size: 60px;
      color: #eee;
      margin-top: 100px;
    }
  }

  .goods-col {
    background: #fff;
    height: 300px;

    .goods-img {
      background: #fafbfc;
      text-align: center;
      height: 180px;
      line-height: 180px;

      i {
        font-size: 60px;
        color: #eee;
        margin-top: 60px;
      }
    }

    .goods-det {
      font-size: 14px;
      text-align: center;
      padding: 10px;
      position: relative;

      p {
        line-height: 20px;
      }

      .price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: #999999;
        font-size: 14px;
        span:first-child{
          color: #e1251b;
        }
      }
    }
  }
}

.goods_module5_wrap{
  .poster-img {
    height: 300px;
    background: #fafbfc;
    text-align: center;
    line-height: 300px;
    i {
      font-size: 60px;
      color: #eee;
      margin-top: 100px;
    }
  }
  .goods-col {
    background: #fff;
    height: 300px;
    .goods-img {
      background: #fafbfc;
      text-align: center;
      height: 180px;
      line-height: 180px;
      i {
        font-size: 60px;
        color: #eee;
        margin-top: 60px;
      }
    }
    .goods-det {
      font-size: 14px;
      text-align: center;
      padding: 10px;
      position: relative;
      p {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 40px;
      }
      .price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: #999999;
        font-size: 14px;
        span:first-child{
          color: #e1251b;
        }
      }
    }
  }
}

.goods-img{
  overflow: hidden;
  :hover{
    transform: scale(1.1);
  }
}
</style>
