<template>
  <div>
    <el-dialog :title="L['APP下载']" :model-value="modalVisible" width="36%" custom-class="download_modal"
               append-to-body @close="$emit('closeModal')">
      <div class="download_code">
        <img src="@/assets/index/code.png" alt="" />
      </div>
      <div class="download_modal_desc">[{{L['扫一扫']}}]</div>
    </el-dialog>
  </div>
</template>

<script>
import {ref, getCurrentInstance, watchEffect} from 'vue';

export default {
  name: 'SldLoginModal',
  components: {},
  props: ['visibleFlag'],
  setup(props, {emit}) {
    const modalVisible = ref(props.visibleFlag);
    const {proxy} = getCurrentInstance();
    const L = proxy.$getCurLanguage();




    watchEffect(() => {
      modalVisible.value = props.visibleFlag;
    })




    return {
      modalVisible,
      L,
    };
  }
}
</script>

<style lang="scss">
@import '../style/loginModal.scss';

.download_modal {
  //.el-dialog {
  //  width: 500px !important;
  //}

  .el-dialog__header {
    background: #ececec;
    .el-dialog__title {
      font-size: 16px;
    }
    .el-dialog__close {
      font-size: 18px;
    }
  }

  .el-dialog__body {
    padding: 10px 20px;
  }

  .el-dialog__headerbtn {
    z-index: 999;
  }
}

.download_modal_title {
  font-size: 20px;
  text-align: center;
}
.download_code {
  width: 200px;
  height: 200px;
  margin: 0 auto 5px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.download_modal_desc {
  font-size: 20px;
  text-align: center;
  color: #6d6d6d;
}
</style>
