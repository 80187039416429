<template>
    <div class="sld_login">
        <div class="sld_login_header">
            <div class="top-nav">
                <NavTopBar />
            </div>
            <div class="logo">
                <NavLogo />
            </div>
        </div>
        <div class="agreement_container">
            <h2 class="agreement_title">{{agreeContent.title}}</h2>
            <pre class="agreement_content" v-html="agreeContent.content"></pre>
        </div>
    </div>
</template>

<script>
    import { useRouter, useRoute } from 'vue-router'
    import { getCurrentInstance, reactive, onMounted } from 'vue';
    import NavTopBar from "@/components/NavTopBar";
    import NavLogo from "@/views/member/login/components/NavLogo";

    export default {
        name: "Agreement",
        components: {
            NavTopBar,
            NavLogo,
        },
        setup() {
            const router = useRouter()
            const route = useRoute()
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const agreeContent = reactive({
                content: '',
                title: ''
            })
            const agreementCode = reactive({
                agreementCode: 'register_agreement'
            })
            const getInitData = () => {
                proxy.$get('/v3/system/front/agreement/detail', agreementCode).then(res => {
                    if (res.state == 200) {
                      agreeContent.title = res.data.title
                      let tempContent = res.data.content
                      let tempDiv = document.createElement('div')
                      tempDiv.innerHTML = tempContent
                      agreeContent.content = tempDiv.innerText || tempDiv.textContent
                    }
                })
            }

            const goRegister = () => {
                // router.push({
                //     path: '/register'
                // })
                router.back();
            }
            onMounted(() => {
                // console.log(route.query.type)
                if (route.query.type == 1) {
                    agreementCode.agreementCode = 'register_agreement';
                }else if (route.query.type == 2) {
                    agreementCode.agreementCode = 'privacy_policy';
                }
                getInitData()
            })

            return { L, agreeContent, goRegister }
        },
    };
</script>
<style lang="scss">
    @import '../../../style/agreement.scss';
    .agreement_container{
        width: 800px;
    }
    .agreement_content{
        font-size: 15px;
        line-height: 35px;
        white-space:normal;
        word-break:break-all;
    }

</style>
