<template>
    <div class="sld_store_item clearfix" :class="{skeleton_sld_store_item:skeleton?skeleton:false}">
<!--        <div class="box_more flex_row_end_center" @click="handleGoStore(item)">-->
<!--            <span>{{L['查看更多商品']}}</span>-->
<!--            <img src="../../assets/store/gengduo@2x.png" alt="" />-->
<!--        </div>-->
        <div class="flex">
            <div class="sld_vendor" @click="toIndexPage(item)">
                <div class="flex">
                    <div class="sld_vendor_logo sld-img-center">
                        <img :src="item.storeLogoUrl" alt="">
                    </div>
                    <div class="store_base_info">
                        <div class="sld_vendor_name overflow_ellipsis">{{curLang == 'zh' ?item.storeName:item.storeNameEn}}</div>
                        <div class="box_num flex">
                            <div class="num_item flex">
                                <div class="key">{{L['商品']}}</div>
                                <div class="val">&nbsp;•{{Number(item.hotGoodsNumber + item.newGoodsNumber).toFixed(0)}}</div>
                            </div>
                            <div class="num_item flex">
                                <div class="key">{{L['关注']}}</div>
                                <div class="val">&nbsp;•{{item.followNumber}}</div>
                            </div>
                            <div class="num_item flex">
                                <div class="key">{{L['质量']}}</div>
                                <div class="val">&nbsp;•{{item.serviceScore}}</div>
                            </div>
                        </div>
                        <div class="btn_group flex">
                            <!--<div class="sld_vendor_bottom">
                                <a class="flex_row_center_center" @click.stop="toIndexPage(item)">
                                &lt;!&ndash;<router-link class="flex_row_center_center" :to="`/store/goods?vid=${item.storeId}`">&ndash;&gt;
                                    <img src="../../assets/store/dianpu@2x.png" alt="" />
                                    <span>{{L['查看店铺']}}</span>
                                </a>
                            </div>-->
                            <div class="sld_vendor_bottom">
                                <a class="flex_row_center_center" @click.stop="kefu">
                                    <img src="../../assets/store/dianpu@2x.png" alt="" />
                                    <span>{{L['在线客服']}}</span>
                                </a>
                            </div>
<!--                            <div class="sld_vendor_bottom">-->
<!--                                <a class="flex_row_center_center">-->
<!--                                    <img src="../../assets/store/dianpu@2x.png" alt="" />-->
<!--                                    <span>{{L['查看资质']}}</span>-->
<!--                                </a>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="store_other_info">
                    <div class="row flex">
                        <div class="item flex_row_start_center">
                            <div class="item_key">
                                <img src="../../assets/store/leixing@2x.png" alt="" />
                                <span>{{L['商家类型']}}：</span>
                            </div>
                            <div class="item_val overflow_ellipsis">{{typeFilter(item.storeType)}}</div>
                        </div>
                        <div class="item flex_row_start_center">
                            <div class="item_key">
                                <img src="../../assets/store/weizhi@2x.png" alt="" />
                                <span>{{L['所在地']}}：</span>
                            </div>
                            <div class="item_val overflow_ellipsis">{{item.areaInfo}}</div>
                        </div>
                    </div>
                    <div class="row flex">
                        <div class="item flex_row_start_start">
                            <div class="item_key">
                                <img src="../../assets/store/leimu@2x.png" alt="" />
                                <span>{{L['经营类目']}}：</span>
                            </div>
                            <div class="item_val overflow_ellipsis_two">{{curLang == 'zh' ? item.mainBusiness : item.mainBusinessEn}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main_lbbox">
                <div class="sld_vendor_goods clearfix">
                    <div class="hd">
                        <a href="javascript:;" class="next" @click="slideWin(1)" ref="next"></a>
                        <a href="javascript:;" class="prev" @click="slideWin(0)" ref="prev"></a>
                    </div>
                    <div class="slide_wrap">
                        <div class="lay_slide_wrap">
                            <ul class="flex bd clearfix">
                                <li class="new_goods" v-for="(newGoods,index) in item.goodsListVOList" :key="index">
                                    <router-link :to="`/goods/detail?productId=${newGoods.defaultProductId || newGoods.productId}`">
                                        <div class="sld_img sld-img-center">
                                            <div class="img" :style="{backgroundImage:'url('+newGoods.goodsImage+')'}"></div>
                                        </div>
                                        <p class="goods_price">￥<span>{{newGoods.goodsPrice}}</span></p>
                                        <p class="sale">{{L['销量']}}：{{formatSales(newGoods.actualSales, 0)}}</p>
                                    </router-link>
                                </li>
                                <div class="empty" v-show="!item.hotGoodsNumber">
                                    <img src="../../assets/goods/empty_data.png" alt="">
                                    <p>{{L['本店暂无商品']}}~</p>
                                </div>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" @refreshInfo="refreshInfo" />
    </div>
</template>

<script>
    import {ref, onMounted, getCurrentInstance, computed} from 'vue'
    // import {useRoute, useRouter} from "vue-router";
    import {useRouter} from "vue-router";
    import {useStore} from "vuex";
    import SldLoginModal from "../../components/loginModal";
    import { formatSales } from "@/utils/common"
    export default {
        name: 'storeListItem',
        props: ["item", "ItemIndex", 'skeleton'],
        components: {
            SldLoginModal
        },
        data() {
            return {
                value: 3
            }
        },
        setup(props) {
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage()
            const curLang = computed(() => store.state.languageType);
            const router = useRouter();
            // const route = useRoute();
            const store = useStore();
            const indexNum = ref(1)
            const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
            //新物品和热销物品按钮切换
            const isOnLogic = (index) => {
                indexNum.value = index
            }
            const score = ref(Number(props.item.serviceScore))
            const colors = ref(['#E2231A', '#E2231A', '#E2231A'])

            const typeFilter = (type) => {
                //11-供货商;12-生产厂家;13-航食公司;14-机场店铺;15-航展主办方;16-出行;17-飞行教育基地;18-高端旅行社;19-文创店;
                // 31-供货商;32-生产厂家;33-承运商;34-承修商;35-航空公司;36-报关代理
                let text = '';
                switch (type) {
                    case 11:
                        text = L["供货商"];
                        break;
                    case 12:
                        text = L["生产厂家"];
                        break;
                    case 13:
                        text = L["航食公司"];
                        break;
                    case 14:
                        text = L["机场店铺"];
                        break;
                    case 15:
                        text = L["航展主办方"];
                        break;
                    case 16:
                        text = L["出行"];
                        break;
                    case 17:
                        text = L["飞行教育基地"];
                        break;
                    case 18:
                        text = L["高端旅行社"];
                        break;
                    case 19:
                        text = L["文创店"];
                        break;
                    case 31:
                        text = L["供货商"];
                        break;
                    case 32:
                        text = L["生产厂家"];
                        break;
                    case 33:
                        text = L["承运商"];
                        break;
                    case 34:
                        text = L["承修商"];
                        break;
                    case 35:
                        text = L["航空公司"];
                        break;
                    case 36:
                        text = L["报关代理"];
                        break;
                }
                return text;
            }


            //商品滑动
            const notClickQuick = ref(true)//防止快速点击造成dom混乱
            const slideWin = (ltr) => {
                let ul = document.getElementsByClassName('bd')[props.ItemIndex]
                let liLength = indexNum.value === 1 ? ul.getElementsByClassName('new_goods').length : ul.getElementsByClassName('hot_goods').length
                let isanimate = false
                let max = liLength % 4 == 0 ? liLength * 190 : (Math.floor(liLength / 4) + 1) * 4 * 190
                let nowMg = parseInt(getComputedStyle(ul, null).marginLeft)
                let slide_wrap_width = max - document.getElementsByClassName('slide_wrap')[props.ItemIndex].offsetWidth
                ul.style.width = max + 'px'
                if (notClickQuick.value) {
                    notClickQuick.value = false
                    if (slide_wrap_width < 0 || isanimate) return;
                    isanimate = true;
                    let n = 190;
                    if (ltr) {
                        nowMg = nowMg - (n * 4)
                        ul.style.marginLeft = (slide_wrap_width >= Math.abs(nowMg) ? nowMg : nowMg + n * 4) + 'px'
                    } else {
                        nowMg = nowMg + (n * 4)
                        ul.style.marginLeft = (nowMg <= 0 ? nowMg : 0) + 'px'
                    }
                    // proxy.$refs.next.style.backgroundColor = slide_wrap_width > Math.abs(nowMg) + 4 * 190 ? "#8d8b8b" : "#ccc"
                    // proxy.$refs.prev.style.backgroundColor = nowMg >= 0 ? "#ccc" : "#8d8b8b"
                    isanimate = false;
                    setTimeout(function () {
                        notClickQuick.value = true;
                    }, 500)
                }

            }
            onMounted(() => {
                // console.log('item', props.item)
                // if (props.item.newGoodsListVOS.length < 5) {
                //     proxy.$refs.next.style.backgroundColor = "#ccc"
                //     proxy.$refs.prev.style.backgroundColor = "#ccc"
                // }
            })
            //关闭登录弹框
            const closeLoingModal = () => {
                loginModalVisibleFlag.value = false;
            };
            const handleGoStore = (item) => {
                router.push({
                    path: `/store/goods`,
                    query: {
                        vid:   item.storeId
                    }
                })
            };
            const toIndexPage = (item) =>{
              proxy.$get('v3/system/front/pcDeco/sellerIndex', {storeId:item.storeId}).then(res => {
                if (res.state == 200) {
                  if (res.data.data) {
                    router.push({
                      path: `/store/index`,
                      query: {
                        vid:  item.storeId
                      }
                    })
                  } else {
                    router.push({ path: `/store/goods`, query: { vid: item.storeId } })
                  }
                } else if (res.state == 257) {
                  router.push({ path: `/store/goods`, query: { vid: item.storeId } })
                }
              })
            };
            const refreshInfo = () => {
                history.go(0)
            };
            const kefu = () => {
                console.log(props.item)
                if (store.state.loginFlag) {
                    let chatInfo = {
                        storeId: props.item.storeId,
                        vendorAvatar: props.item.storeLogoUrl,
                        storeName: curLang.value == 'zh'?props.item.storeName:props.item.storeNameEn,
                        source: '从店铺详情页进入'
                    }
                    store.commit('saveChatBaseInfo', chatInfo)


                    let newWin = router.resolve({
                        path: '/service',
                        query: {
                            vid: props.item.storeId
                        }
                    })

                    window.open(newWin.href, "_blank")
                } else {
                    //未登录提示登录
                    loginModalVisibleFlag.value = true;
                }
            };

            return {
                L,
                slideWin,
                indexNum,
                loginModalVisibleFlag,
                isOnLogic,
                score,
                colors,
                curLang,
                closeLoingModal,
                refreshInfo,
                handleGoStore,
                toIndexPage,
                kefu,
                typeFilter,
                formatSales,
            }

        },
    }
</script>

<style lang="scss" scoped>
    @import "@/style/storeList.scss";
    @import "@/style/base.scss";

    .empty {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            color: #666;
            margin-left: 11px;
        }
    }
</style>
