<template>
  <div class="choose-company">
    <el-dialog :title="L['选择企业']" v-model="modalVisible" @close="close_dialog" width="500px" :close-on-click-modal="false" :show-close="false">
      <el-form ref="ruleForm" :model="companyForm.data" :rules="rules">
        <el-form-item :label="L['选择企业'] + '：'" prop="companyAuthId">
          <el-select v-model="companyForm.data.companyAuthId" :placeholder="L['请选择']" :popper-append-to-body="false" style="width: 100%"
                     :no-data-text="L['暂无数据']">
            <el-option
                v-for="item in companyList"
                :key="item.companyAuthId"
                :label="curLang == 'zh'?item.companyAuthName:item.companyAuthNameEn"
                :value="item.companyAuthId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="submit('ruleForm')" :loading="btnLoading">{{L['保 存']}}</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {computed, getCurrentInstance, reactive, ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "ChooseCompany",
  props: ['companyList'],
  setup(props, {emit}) {
    const modalVisible = ref(true);
    const {proxy} = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const store = useStore()
    const btnLoading = ref(false);
    const companyAuthId = ref("");
    const curLang = computed(() => store.state.languageType);
    const ruleForm = ref(null);
    const companyForm = reactive({
      data: {
        companyAuthId: ''
      }
    });
    const rules = {
      companyAuthId: [{ required: true, message: L["选择企业"], trigger: "change" }],
    }; //输入校验
    const close_dialog = () => {
      emit('close', companyForm.data.companyAuthId);
    }
    const submit = () => {
      btnLoading.value = true
      ruleForm.value.validate(valid => {
        if (valid) {
          close_dialog();
          btnLoading.value = false
        }else {
          btnLoading.value = false
        }
      })
    }
    return {
      L,
      curLang,
      modalVisible,
      btnLoading,
      companyAuthId,
      companyForm,
      rules,
      ruleForm,
      close_dialog,
      submit,
    }
  }
}
</script>

<style scoped>

</style>
