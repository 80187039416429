<template>
  <el-dialog title='选择规格' append-to-body :model-value="modalVisible" width="640px" :before-close="handleClose"
    @open="handleOpen">
    <div class="add-cart-main" v-if="detail.data.goodsId">
      <div class="goods-info">
        <img :src="imgUrl" alt="">
        <div class="info">
          <h2 class="name">{{detail.data.goodsName}}</h2>
          <div class="price">
            <span>￥</span>
            <h2>{{detail.data.defaultProduct.productPrice && detail.data.defaultProduct.productPrice.toFixed(2)}}</h2>
            <p>销量 {{detail.data.sales}} 件</p>
          </div>
        </div>
      </div>
      <div class="spec-main">
        <div class="spec-box" v-for="(item, index) in detail.data.specs" :key="index">
          <span>{{item.specName}}</span>
          <div v-for="(res, i) in item.specValueList" :key="i" v-show="res.checkState != '3'"
            :class="{'active': res.checkState == 1}" @click="chooseSpec(item.specId, res.specValueId)">
            <div class="img-box" v-if="res.image">
              <img :src="res.image" alt="" :title="res.specValue">
            </div>
            <div class="text-box" v-else>
              <span>{{res.specValue}}</span>
            </div>
            <img src="@/assets/goods/check_mark.png" alt="" class="check_mark" v-if="res.checkState == '1'" />
          </div>
        </div>
      </div>
      <div class="num-box">
        <span>数量</span>
        <el-input-number v-model="num" size="mini" :min="1" :max="detail.data.defaultProduct.productStock || 999">
        </el-input-number>
        <p>库存{{detail.data.defaultProduct.productStock}}</p>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="handleClose" size="mini">取消</el-button>
        <el-button type="primary" @click="submit" size="mini">确定</el-button>
      </div>
    </template>

  </el-dialog>
</template>
<script>
import {computed, getCurrentInstance, reactive, ref, watchEffect} from "vue";
import {useRouter} from "vue-router";

  export default {
    name: '',
    components: {

    },
    props:['dialogVisible','item','type'],
    setup(props,{emit}){
      const {proxy} = getCurrentInstance();
      const router = useRouter();
      const detail = reactive({data:{}});
      const num = ref(1);
      const productId = reactive({data:''});
      const modalVisible = ref(props.dialogVisible)
      const item = reactive(props.item);
      const type = reactive(props.type)
      const btnLoading = ref(false)
      const imgUrl = computed(()=>detail.data.defaultProduct && detail.data.defaultProduct.goodsPics && detail.data.defaultProduct.goodsPics.length ? detail.data.defaultProduct.goodsPics[0] : '')
      watchEffect(() => {
        modalVisible.value = props.dialogVisible;
      })
      const handleClose = () => {
        emit("close");
      };
      const handleOpen = () => {
        console.log(item.data)
        num.value = item.data.num;
        productId.data = '';
        getDetail(item.data.defaultProductId)
      };
      const getDetail = (id) => {
        proxy.$get('v3/goods/front/goods/details', {
          productId: id
        }).then(res => {
          console.log(res,'detail')
          if (res.state == 200) {
            detail.data = res.data;
            console.log(detail.data.goodsId)
          }
        })
      };
      const getDynamic = (productId) => {
        proxy.$get('v3/goods/front/goods/details2', {
          productId: productId
        }).then(res => {
          if (res.state == 200) {
            let dynamicData = ['defaultProduct', 'deliverInfo', 'effectSpecValueIds', 'followGoods', 'specs',
              'storeInf', 'sales', 'state', 'shareLink', 'shareImage', 'isVirtualGoods'
            ]
            dynamicData.forEach((item) => {
              detail[item] = res.data[item]
            })
          }
        })
      };
      const chooseSpec = (specId, specValueId) => {
        let arr = [];
        detail.data.specs.forEach(res => {
          if (res.specId == specId) {
            arr.push(specValueId)
          } else {
            let obj = res.specValueList.find(o => {
              return o.checkState == 1
            })
            arr.push(obj.specValueId)
          }
        })
        proxy.$get("v3/goods/front/goods/productInfo", {
          goodsId: detail.data.goodsId,
          specValueIds: arr.join(',')
        }).then((res) => {
          if (res.state == 200) {
            let result = res.data;
            detail.data.defaultProduct = result.defaultProduct;
            productId.data = result.defaultProduct.productId;
            detail.data.specs = result.specs;
            getDynamic(productId.data)
          }
        });
      };
      const submit = () => {
        console.log('submit',type)
        btnLoading.value = true;
        if (type.data == 'inquiry') {
          router.push({
            path: '/goods/detail',
            query: {
              productId: detail.data.defaultProduct.productId
            },
          })
        }else if (type.data == 'order') {
          let query = {
            orderType: 1,
            goodsId: detail.data.goodsId,
            productId: detail.data.defaultProduct.productId,
            numbers: num.value,
            ifcart: false,
          }
          if (detail.data.defaultProduct.promotionType == 102) {
            query.isAloneBuy = true
          }
          console.log(query)
          router.push({
            path: "/buy/confirm",
            query,
          })
        }
      }
      return{
        detail,
        num,
        productId,
        modalVisible,
        handleClose,
        handleOpen,
        getDetail,
        getDynamic,
        chooseSpec,
        submit,
        btnLoading,
        imgUrl,
      }
    },
  }
</script>
<style lang="scss" scoped>
  .add-cart-main {
    padding: 20px 40px;

    .goods-info {
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px;
        background: #D8D8D8;
        border-radius: 8px;
      }

      .info {
        flex: 1;
        height: 100px;
        overflow: hidden;
        padding-left: 20px;

        .name {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #303030;
          line-height: 18px;
          margin: 0;
        }

        .price {
          margin-top: 20px;
          display: flex;
          align-items: baseline;

          span {
            color: #FF4304;
            font-size: 12px;
            margin-right: 4px;
          }

          h2 {
            color: #FF4304;
            font-size: 18px;
            margin: 0;
          }

          p {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9F9F9F;
            margin: 0 0 0 40px;
          }
        }
      }

    }

    .spec-main {
      margin-top: 12px;
    }

    .spec-box {
      display: flex;
      align-items: center;
      margin-top: 12px;

      >span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303030;
        margin-right: 20px;
      }

      >div {
        margin: 0 10px 0 0;
        position: relative;

        >img {
          width: 14px;
          height: 14px;
          position: absolute;
          bottom: 0;
          right: 0;
        }

        .img-box {
          width: 48px;
          height: 48px;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          >img {
            max-width: 100%;
            max-height: 100%;

          }
        }

        .text-box {
          cursor: pointer;

          span {
            display: inline-block;
            max-width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 3px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            display: block;
            padding: 10px 20px;
            box-sizing: border-box;
          }
        }
      }
    }

    .num-box {
      display: flex;
      align-items: center;
      margin-top: 12px;

      >span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303030;
        margin-right: 20px;
      }

      >p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 30px;
        margin: 0 0 0 17px;
      }
    }
  }
</style>
