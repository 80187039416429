<template>
    <div class="top_header" :class="{fixed_header: searchBarFixed}">
        <NavTopBar/>
        <SldHomeTopSearch/>
        <NavCatHeader/>
        <div class="bottom_line"></div>
    </div>
    <div class="hidden_header">
        <NavTopBar/>
        <SldHomeTopSearch/>
        <NavCatHeader/>
        <div class="bottom_line"></div>
    </div>
  <router-view></router-view>
  <FooterService />
  <FooterLink />
</template>

<script>
  import NavTopBar from "../../components/NavTopBar";
  import SldHomeTopSearch from "../../components/SldHomeTopSearch";
  import FooterService from "../../components/FooterService";
  import FooterLink from "../../components/FooterLink";
  import { useRouter } from "vue-router";
  import { ref, getCurrentInstance } from "vue";
  import { useStore } from "vuex";
  export default {
    name: 'Cart',
    components: {
      NavTopBar,
      SldHomeTopSearch,
      FooterService,
      FooterLink,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const isRouterAlive = true
      const store = useStore();
      const configInfo = ref(store.state.configInfo)
      const router = useRouter();
      const keyword = ref(
        router.query != undefined &&
          router.query.keyword != undefined &&
          router.query.keyword
          ? router.query.keyword
          : ""
      );
        const searchBarFixed = ref(false);
        //滚动事件
        const handleScroll = () => {
            var height = 600;
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > height) {
                searchBarFixed.value = true;
            } else {
                searchBarFixed.value = false;
            }
        };
        window.addEventListener("scroll", handleScroll);

      const search = () => {
        proxy.$refs.searchInputCart.style.color = 'rgb(153,153,153)'
        console.log('search')
        if (keyword.value) {
          router.push({
            path: `/goods/list`,
            query: { keyword: keyword.value }
          });
        }
      };

      const inputFocus = () => {
        proxy.$refs.searchInputCart.style.color = '#333'
      }

      return {
        configInfo,
        keyword,
        search,
        isRouterAlive,
        L,
        inputFocus,
          searchBarFixed
      }
    }
  }
</script>

<style lang="scss">
.top_header {
    width: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    &.fixed_header {
        width: 100%;
        position: fixed;
        background-color: #fff;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1000;
    }
}
.hidden_header {
    background-color: #fff;
    visibility: hidden;
}
  .sld_cart_top {
    width: 1200px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;

    .sld_home_top_search_left {
      position: relative;
      float: left;
      width: 331px;
      margin-top: 24px;

      .sld_logo_wrap {
        display: block;
        width: 160px;
        height: 80px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .sld_seach_wrap {
      width: 590px;
      padding-top: 4px;
      margin-top: 30px;

      .sld_seach_box {
        width: 580px;
        z-index: 11;
        height: 38px;
        margin-bottom: 3px;
        border: 2px solid $colorMain;

        .form {
          background-color: $colorMain;
          height: 34px;
          overflow: hidden;

          .text {
            height: 36px;
            background-color: #fff;
            width: 471px;
            -webkit-appearance: none;
            -webkit-border-radius: 0;
            height: 34px;
            padding: 5px 5px 5px 10px;
            background-position: 0 -360px;
            background-color: #fff;
            background-repeat: repeat-x;
            line-height: 20px;
            font-family: arial, "\5b8b\4f53";
            font-size: 12px;
            outline: none;
            border: none;
          }

          input {
            margin: 0;
            padding: 0;
            height: 34px;
            border: 0;
          }

          input::placeholder {

            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(153, 153, 153);
          }

          ::-webkit-input-placeholder {

            margin-left: 20px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(153, 153, 153);
          }

          /* 使用webkit内核的浏览器 */
          :-moz-placeholder {

            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(153, 153, 153);
          }

          /* Firefox版本19+ */
          :-ms-input-placeholder {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(153, 153, 153);
          }

          .button {
            width: 103px;
            background: $colorMain;
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            float: right;
            cursor: pointer;
          }
        }

        .hot_search_wrap {
          height: 30px;
          line-height: 30px;
          color: #999;
          overflow: hidden;

          strong {
            float: left;
            font-weight: 400;
          }

          a {
            color: #666;

            &:link,
            &:visited {
              float: left;
              margin-right: 10px;
            }

            &:hover {
              color: $colorMain;
            }
          }
        }
      }
    }
  }
</style>
