<template>
  <div class="login-main">
    <div class="qrcode-main">
      <h2>{{L['扫码登录']}}</h2>
      <div class="qrcode">
        <div class="qrMain_code_bg"
             :style="`filter:blur(${QRout?'1px':'0'});background-image:url(${QRcode});background-size:112% 112%;background-position:center;`"></div>
        <div v-if="QRout" class="qrMain_code_cover">
          <p>{{L['二维码已失效']}}</p>
          <div @click="reCreateQRcode">{{L['点击刷新']}}</div>
        </div>
        <div v-else-if="QRsuccess" class="qrMain_code_cover">
            <p class="qrMain_code_cover_p">{{L['登录成功']}}</p>
          </div>
      </div>
      <p>{{L['使用民航商城APP扫码登录']}}</p>
    </div>
    <div class="line"></div>
    <div class="login-wrap">
      <div class="tabs-box">
        <h3 :class="{'active': loginFrom.loginType == 2}" @click="loginFrom.loginType = 2">{{L['验证码登录']}}</h3>
        <h3 :class="{'active': loginFrom.loginType == 1}" @click="loginFrom.loginType = 1">{{L['密码登录']}}</h3>
        <div :class="'left' + loginFrom.loginType"></div>
      </div>
      <template v-if="loginFrom.loginType == 2">
        <div class="input-box">
          <img src="@/assets/login/ac.png" alt="">
          <el-input v-model="loginFrom.username" :placeholder="L['输入手机号/邮件账号']" clearable />
        </div>
        <div class="input-box">
          <img src="@/assets/login/code.png" alt="">
          <el-input v-model="loginFrom.code" placeholder="输入验证码" clearable />
          <p @click="getCode">{{sendMsgStr}}</p>
        </div>
      </template>
      <template v-if="loginFrom.loginType == 1">
        <div class="input-box">
          <img src="@/assets/login/ac.png" alt="">
          <el-input v-model="loginFrom.username" :placeholder="L['输入账号/手机号/邮件账号']" clearable />
        </div>
        <div class="input-box">
          <img src="@/assets/login/pw.png" alt="">
          <el-input v-model="loginFrom.password" :placeholder="L['请输入密码']" show-password />
        </div>
      </template>
      <p class="forget">
        <span @click="handleFind">{{L['忘记密码']}}</span>
      </p>
      <p class="btn" @click="handleLogin" v-loading="loginLoading" element-loading-spinner="el-icon-loading">{{L['登录']}}</p>
      <div class="bottom">
        <el-checkbox v-model="checked"></el-checkbox>
        <p>
          <span>{{L['我已阅读并同意']}}</span>
          <span class="link" @click="handleGoLink(1)">{{L['《航云商城用户协议》']}}</span>
          <span>{{L['和']}}</span>
          <span class="link" @click="handleGoLink(2)">{{L['《隐私协议》']}}</span>
        </p>
      </div>
    </div>
    <div class="tips-box" @click="$emit('goRegister')">
      {{L['注册']}}
    </div>
    <choose-company v-if="visibleFlag" :companyList="companyList.data" @close="handleChoose"></choose-company>
  </div>
</template>
<script>
  // import {
  //   isPhone,
  //   isEmail
  // } from '@/utils/validate.js'
  import {checkPhone, checkEmail, checkMemberName, myBrowser, IEVersion} from '@/utils/common'
  import {
    mapActions,
    useStore
  } from 'vuex'
  import {computed, getCurrentInstance, reactive, ref, onUnmounted} from "vue";
  import {ElMessage} from "element-plus";
  import {useRouter, useRoute} from "vue-router";
  import ChooseCompany from "@/components/ChooseCompany";
  export default {
    name: '',
    components: {
      ChooseCompany
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const router = useRouter();
      const route = useRoute();
      const store = useStore();
      const loginFrom = reactive({
        username: '',
        password: '',
        code: '',
        device: '',
        loginType: 2,
        source: 1,
      });
      const QRcode = ref(''); // 二维码
      const uCode = ref('');  // 授权识别码
      const QRissend = ref(null); //二维码登录定时器
      const QRcheck = ref(null);  //二维码循环定时器
      const QRsuccess = ref(false); //二维码登录成功
      const QRout = ref(false); //二维码是否过期
      const checked = ref(true);
      const loginLoading = ref(false);
      const companyList = reactive({ data: [] });
      const visibleFlag = ref(false);
      const curLang = computed(() => store.state.languageType);
      const times = ref(60);
      const accessToken = ref('');

      // 选择企业
      const handleChoose = (companyAuthId) => {
        store.commit("updateCompanyAuthId", companyAuthId);
        visibleFlag.value = false;
        let redirectUrl = route.query.redirectUrl;
        if (redirectUrl) {
          window.location.href = `${redirectUrl}?token=${accessToken.value}&companyAuthId=${companyAuthId}&language=${curLang.value}`
        }else {
          if (window.history.state.back) {
            router.back();
          } else {
            router.replace({ path: '/index' })
          }
        }
        if (loginLoading.value) {
          loginLoading.value = false;
          clearTimeout(QRissend.value);
          clearInterval(QRcheck.value);
          QRissend.value = null;
          QRcheck.value = null;
        }
      }

      const handleGoLink = (type) => {
        let routeUrl = router.resolve({
          path: '/agreement',
          query: {
            type: type
          }
        })
        window.open(routeUrl.href, '_blank');
      }

      // 刷新二维码
      const reCreateQRcode = () => {
        setTimeout(() => {
          QRout.value = false;
          getQRcode();
        }, 200)
      }
      const getQRcode = () => {
        proxy.$get('/v3/member/front/qr/login/createQr').then(res => {
          if (res.state == 200) {
            QRcode.value = 'data:image/png;base64,' + res.data.image;
            uCode.value = res.data.u;
            /* 过期定时器 */
            QRissend.value = setTimeout(()=>{
              console.log('-----二维码过期-----')
              clearTimeout(QRissend.value);
              clearInterval(QRcheck.value);
              QRissend.value = null;
              QRcheck.value = null;
              QRout.value = true;
            }, 300000);
            /* ------ end ------ */
            getAuthorizeSchedule();
          }
        })
      };
      const getAuthorizeSchedule = () => {
        QRcheck.value = setInterval(() => {
          let opt = {
            u: uCode.value
          }
          proxy.$post('/v3/member/front/qr/login/progress', opt).then(res => {
            // console.log('uCode', res)
            if (res.state == 200) {
              if (res.data.progress == 2) {
                QRsuccess.value = true;
                clearTimeout(QRissend.value);
                clearInterval(QRcheck.value);
                QRissend.value = null;
                QRcheck.value = null;
                authorizeLogin(res.data.refreshToken);
              }else if (res.data.progress == 3) {
                clearInterval(QRcheck.value);
                QRcheck.value = null;
              }
            }
          })
        }, 1000)
      }
      const authorizeLogin = (token) => {
        let param = new FormData();
        param.append('grant_type', 'refresh_token');
        param.append('refresh_token', token);
        proxy.$post('v3/frontLogin/oauth/token', param, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Basic VVcxS2FsSnVTblppYmxFOTpVMjFHTWxsVlFrUmlNMEkxVlcxc2JtRklVa0ZWTW5oMldrYzVkUT09'
          }
        }).then(res => {
          if (res.state == 200) {
            //将用户信息存缓存，并跳转到首页
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
            getInfo(res.data.access_token);
          } else {
            //提示错误
            ElMessage.error(res.msg)
          }
        })
      }
      const sendMsgStr = computed(() => {
        if (times.value == 60) {
          return L["获取验证码"]
        } else {
          return times.value + L["s后获取"]
        }
      });
      const handleFind = () => {
        router.push({
          path: '/pwd/forget'
        })
      }
      getQRcode();
      const handleLogin = () => {
        if (loginFrom.loginType == 2) {
          if (!loginFrom.username) {
            ElMessage.error(L["输入手机号/邮件账号"])
            return
          } else {
            if (checkPhone(loginFrom.username) !== true && checkEmail(loginFrom.username) !== true) {
              ElMessage.error(L['输入手机号/邮件账号'])
              return
            }
          }
          if (!loginFrom.code) {
            ElMessage.error('请输入验证码')
            return
          }
        }else {
          if (!loginFrom.username) {
            ElMessage.error(L["输入账号/手机号/邮件账号"])
            return
          } else {
            if (checkMemberName(loginFrom.username) !== true && checkPhone(loginFrom.username) !== true && checkEmail(loginFrom.username) !== true) {
              ElMessage.error(L['输入账号/手机号/邮件账号'])
              return
            }
          }
          if (!loginFrom.password) {
            ElMessage.error('请输入密码')
            return
          }
        }

        if (!checked.value) {
          ElMessage.error('请同意航云商城用户协议和隐私协议')
          return
        }
        let browserVersion = myBrowser();
        if (browserVersion == 'IE') {
          browserVersion += IEVersion();
        }
        const param = {
          username: loginFrom.username,
          password: loginFrom.loginType == 2 ? loginFrom.code : loginFrom.password,
          loginType: loginFrom.loginType,
          device: browserVersion,
          source: 1,
          app: 'pc端',
        }
        loginLoading.value = true;
        proxy.$post("v3/frontLogin/oauth/token", param).then(res => {
          if (res.state == 200) {
            //将用户信息存缓存，并跳转到首页
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
            getInfo(res.data.access_token);
          } else {
            //提示错误
            ElMessage.error(res.msg)
            loginLoading.value = false;
          }
        });
      };
      //获取用户信息，并同步信息到vuex
      const getInfo = (token) => {
        proxy.$get("/v3/member/front/member/memberInfoWithAuth").then(res => {
          if (res.state == 200) {
            let companyAuthId = '';
            let arr = res.data.companyList;
            if (arr.length > 0) {
              if (arr.length == 1) {
                companyAuthId = arr[0].companyAuthId;
                res.data.companyAuthId = companyAuthId;
                store.commit("updateCompanyAuthId", companyAuthId);
                store.commit("updateMemberInfo", res.data);
                proxy.$getLoginCartListData(); //更新购物车数据
                let redriectUrl = route.query.redriectUrl;
                if (redriectUrl) {
                  store.commit("clearAllData"); //清除数据
                  store.commit('clearCartStorage')
                  proxy.$socket.disconnect()
                  window.location.href = `${redriectUrl}?token=${token}&companyAuthId=${companyAuthId}&language=${curLang.value}`
                }else {
                  if (window.history.state.back) {
                    router.back();
                  } else {
                    router.replace({ path: '/index' })
                  }
                }
                if (loginLoading.value) {
                  loginLoading.value = false;
                  clearTimeout(QRissend.value);
                  clearInterval(QRcheck.value);
                  QRissend.value = null;
                  QRcheck.value = null;
                }
              }else {
                store.commit("updateMemberInfo", res.data);
                proxy.$getLoginCartListData(); //更新购物车数据
                accessToken.value = token;
                companyList.data = arr;
                visibleFlag.value = true;
              }
            }else {
              res.data.companyAuthId = companyAuthId;
              store.commit("updateCompanyAuthId", companyAuthId);
              store.commit("updateMemberInfo", res.data);
              proxy.$getLoginCartListData(); //更新购物车数据
              let redriectUrl = route.query.redriectUrl;
              // return;
              if (redriectUrl) {
                window.location.href = `${redriectUrl}?token=${token}&companyAuthId=${companyAuthId}&language=${curLang.value}`
              }else {
                if (window.history.state.back) {
                  router.back();
                } else {
                  router.replace({ path: '/index' })
                }
              }
              if (loginLoading.value) {
                loginLoading.value = false;
                clearTimeout(QRissend.value);
                clearInterval(QRcheck.value);
                QRissend.value = null;
                QRcheck.value = null;
              }
            }
          }
        });
      }
      const getCode = () => {
        if (!loginFrom.username) {
          ElMessage.error(L['输入手机号/邮件账号'])
          return
        } else {
          if (checkPhone(loginFrom.username) !== true && checkEmail(loginFrom.username) !== true) {
            ElMessage.error(L['输入手机号/邮件账号'])
            return
          }
        }
        if (times.value != 60) {
          return
        }
        times.value--
        const timer = setInterval(() => {
          times.value--
          if (times.value == 0) {
            clearInterval(timer)
            times.value = 60
          }
        }, 1000)
        proxy.$get('/v3/msg/front/commons/sendLoginCode', {
          verifyAddr: loginFrom.username,
          verifyType: checkEmail(loginFrom.username) === true ? 1 : 2,
        }).then(res => {
          if (res.state == 200) {
            ElMessage.success('验证码已发送!');
          }else {
            ElMessage.success(res.msg);
          }
        })
      };
      onUnmounted(() => {
        clearTimeout(QRissend.value);
        clearInterval(QRcheck.value);
        QRissend.value = null;
        QRcheck.value = null;
      });
      return {
        L,
        curLang,
        QRcode,
        QRsuccess,
        QRout,
        loginFrom,
        checked,
        times,
        sendMsgStr,
        loginLoading,
        visibleFlag,
        companyList,
        handleLogin,
        handleFind,
        getCode,
        reCreateQRcode,
        handleGoLink,
        handleChoose,
      }
    },
    methods: {
      ...mapActions({
        login: 'user/login',
        setUserInfo: 'user/setUserInfo',
      }),
    },
  }
</script>
<style lang="scss">
  .login-main {
    width: 610px;
    height: 430px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 15px;
    display: flex;
    padding: 50px 30px 40px 55px;

    .qrcode-main {
      width: 150px;
      padding-top: 30px;

      >h2 {
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303030;
        line-height: 28px;
      }

      >div {
        position: relative;
        width: 150px;
        height: 150px;
        margin-top: 20px;

        .qrMain_code_bg {
          width:100%;
          height:100%;
        }
        .qrMain_code_cover {
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.6);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9;
          color: #FFFFFF;
          text-align: center;
          p {
            font-size: 19px;
            font-weight: 700;
            margin-top: 35px;
            margin-bottom: 40px;
          }
          .qrMain_code_cover_p {
            margin-top: 65px;
          }
          div {
            width: 100px;
            line-height: 34px;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 1px;
            background: #006DE4;
            border-radius: 3px;
            margin: 0 auto;
            cursor: pointer;
          }
        }
      }

      >p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        text-align: center;
        margin-top: 17px;
      }
    }

    .line {
      width: 1px;
      height: 340px;
      background-color: #E7E7E7;
      margin: 0 39px 0 35px;
    }

    .tips-box {
      position: absolute;
      top: 0;
      right: 0;
      width: 78px;
      height: 78px;
      background-image: url('../../../../assets/login/login-bg.png');
      background-size: 78px 78px;
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 22px;
      padding: 13px 7px 0 0;
      text-align: right;
      cursor: pointer;
    }

    .login-wrap {

      .tabs-box {
        display: flex;
        height: 42px;
        position: relative;

        >h3 {
          flex: 1;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303030;
          line-height: 40px;
          cursor: pointer;
          text-align: center;
          margin: 0;

          &.active {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #006DE4;
            line-height: 40px;
          }
        }

        >div {
          width: 50px;
          height: 2px;
          background-color: #006DE4;
          position: absolute;
          transition: left .2s cubic-bezier(.34, .69, .1, 1);
          bottom: 0;

          &.left2 {
            left: 50px;
          }

          &.left1 {
            left: 200px;
          }
        }
      }

      .input-box {
        width: 300px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #EFEFEF;
        display: flex;
        align-items: center;
        padding: 0 12px;
        margin-top: 24px;

        >div {
          flex: 1;
        }

        input {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgb(29, 33, 41);
          outline: none;
          appearance: none;
          border: none;
          background: none;
        }

        p {
          cursor: pointer;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FF4304;
          margin: 0;
        }
      }

      .forget {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #737373;
        line-height: 20px;
        margin: 10px 0 30px;
        span {
          cursor: pointer;
        }
      }

      .btn {
        width: 300px;
        height: 44px;
        padding: 10px 0;
        text-align: center;
        background: #006DE4;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 24px;
        margin-top: 30px;
        margin-bottom: 15px;
        cursor: pointer;

        .el-loading-spinner {
          margin-top: -12px;
          .el-icon-loading {
            font-size: 18px;
          }
        }
      }

      .bottom {
        height: 18px;
        display: flex;
        align-items: flex-start;

        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          margin: 0 0 0 10px;

          span {
            color: #303030;
            line-height: 17px;
            &.link {
              color: #006DE4;
              margin: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>
