<!--
 * @Author: your name
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2021-01-11 10:47:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/components/NavCatHeader.vue
-->
<template>
  <div class="nav_cat">
    <div class="header">
      <div class="product_sort" @click="toGoodsList">
        <img :src="sortUrl" alt />
        <span class="sort">{{L['全部商品分类']}}</span>
        <CategorySort />
      </div>
      <nav>
        <li v-if="showIndex"><a href="/index">{{L['首页']}}</a></li>
        <li v-if="showIndex" @click.stop="openDownLoad"><a>{{L['网上航展']}}</a></li>
        <li v-if="showIndex" @click.stop="openDownLoad"><a>{{L['机场休闲']}}</a></li>
        <li v-if="showIndex" @click.stop="openDownLoad"><a>{{L['飞翔俱乐部']}}</a></li>
        <li v-if="showIndex" @click.stop="openDownLoad"><a>{{L['出行定制']}}</a></li>
        <li v-for="(item,index) in navList.data" :key="index">
          <a v-if="curLang == 'zh'" href="javascript:void(0)" @click="navClick(item)">{{item.navName}}</a>
          <a v-else href="javascript:void(0)" @click="navClick(item)">{{item.navNameEn}}</a>
        </li>
      </nav>
    </div>
    <down-load-modal :visibleFlag="downLoadVisibleFlag" @closeModal="closeModal"></down-load-modal>
  </div>

</template>

<script>
  import { useRouter, useRoute } from "vue-router"; //引入路由
  import { reactive, getCurrentInstance, onMounted, ref, computed } from "vue";
  import CategorySort from './CategorySort'
  import {useStore} from "vuex";
  import downLoadModal from "@/components/downLoadModal";
  export default {
    name: "NavCatHeader",
    components: {
      CategorySort,
      downLoadModal
    },
    setup() {
      const router = useRouter(); //使用路由
      const route = useRoute();
        const store = useStore();
      const showIndex = ref(true)
      const downLoadVisibleFlag = ref(false)
      const navList = reactive({ data: [] });
      const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const curLang = computed(() => store.state.languageType);
      const sortUrl = require("@/assets/header/sort.png");
      const toGoodsList = () => {
        router.push("/goods/Category"); //push产品分类路由
      };

      const openDownLoad = () => {
        downLoadVisibleFlag.value = true;
      }

      //关闭弹框
      const closeModal = () => {
        downLoadVisibleFlag.value = false;
      };
      const getNavData = () => {
        proxy.$get("v3/system/front/navigation/list").then(res => {
          if (res.state == 200) {
            navList.data = res.data;
          }
        });
      };
      const navClick = val => {
        proxy.$diyNavGo(JSON.parse(val.data.replace(/&quot;/g,"\"")));
      };
      onMounted(() => {
        // let path = route.path;
        // let dom = document.getElementById('category_sort')
        // if (path == '/index') {
        //   dom.style.display = 'block'
        //   showIndex.value = false
        // } else if (path == '/goods/Category') {
        //   dom.style.display = 'none'
        // }
        getNavData();
      })

      return { L, navList, sortUrl, toGoodsList, navClick, showIndex, curLang, openDownLoad, closeModal, downLoadVisibleFlag };
    }
  };
</script>

<style lang="scss" scoped>
.nav_cat {
    position: relative;
    z-index: 10;
}
  .header {
    width: $min-home-width;
    height: 45px;
    margin: 0 auto;
    display: flex;
    overflow: visible;

    .product_sort {
      position: relative;
      overflow: visible;
      width: 187px;
      height: 100%;
      background: $colorMain;
      color: #fff;
      font-size: 16px;
      //letter-spacing: 3px;
      line-height: 30px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      //justify-content: space-between;
      cursor: pointer;
      box-sizing: border-box;
      padding: 0 26px;

      &:hover {
        #category_sort {
          display: block;
        }
      }

      img {
        width: 12px;
        height: 12px;
      }

      .sort {
        margin-left: 10px;
      }
    }

    nav {
      flex: 1;
      //width: 1013px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      line-height: 30px;
      overflow: hidden;
      flex-wrap: wrap;

      a {
        height: 45px;
        display: inline-block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #333333;
        font-size: 16px;
        margin: 0 0 0 20px;
        line-height: 45px;
        padding: 0 7px;
        width: max-content;
      }

      a:hover {
        color: $colorMain;
        border-bottom: 3px solid $colorMain;
      }
    }
  }
</style>
