<template>
  <div class="index">
    <div class="banner">
      <top-banner :list="topBanner.data"></top-banner>
    </div>
    <div class="index_lay">
      <div class="top_info">
        <div class="box_user_info">
          <div class="user_info">
            <img class="img_avatar" v-if="memberInfo.memberAvatar" :src="memberInfo.memberAvatar" alt=""/>
            <img class="img_avatar" v-else src="../../assets/index/touxiang@2x.png" alt=""/>
            <div class="desc">{{ L['您好，欢迎来到'] }}{{ L['民航商城'] }}</div>
            <div class="box-btn" v-if="!memberInfo.memberId">
              <div class="btn-login" @click="handleGoPath('/login')">{{ L['用户登录'] }}</div>
<!--              <div class="btn-center" @click="goToPage('https://supplier.caacmall.com/#/login')">{{L['商家入驻']}}</div>-->
              <div class="btn-center" @click="goToPage('http://supplier.caacmall.cn/#/login')">{{L['商家入驻']}}</div>
<!--              <div class="btn-center" @click="goToPage('http://supplier.caacmall.net/#/login')">{{ L['商家入驻'] }}</div>-->
            </div>
            <div class="box-btn" v-else>
              <div class="btn-workspace" @click="goWithLogin('Index')">{{ L['进入工作台'] }}</div>
            </div>
          </div>
          <div class="user_module">
            <div class="more">
              <span>{{ L['更多服务'] }}</span>
            </div>
            <div class="module_list">
              <div class="item" @click="goWithLogin('Ordinary')">
                <img class="item_icon" src="../../assets/index/wddd@2x.png" alt=""/>
                <div class="item_name">{{ L['我的订单'] }}</div>
              </div>
              <div class="item" @click="handleGoPath('/cart/index')">
                <img class="item_icon" src="../../assets/index/gwc@2x.png" alt=""/>
                <div class="item_name">{{ L['我的购物车'] }}</div>
              </div>
              <div class="item" @click="goWithLogin('MyCollect')">
                <img class="item_icon" src="../../assets/index/wdfw@2x.png" alt=""/>
                <div class="item_name">{{ L['我的收藏'] }}</div>
              </div>
              <!--              <div class="item">-->
              <!--                <img class="item_icon" src="../../assets/index/wdhy@2x.png" alt="" />-->
              <!--                <div class="item_name">{{L['会员中心']}}</div>-->
              <!--              </div>-->
              <!--              <div class="item">-->
              <!--                <img class="item_icon" src="../../assets/index/wdcg@2x.png" alt="" />-->
              <!--                <div class="item_name">{{L['我的采购']}}</div>-->
              <!--              </div>-->
              <!--              <div class="item">-->
              <!--                <img class="item_icon" src="../../assets/index/cggg@2x.png" alt="" />-->
              <!--                <div class="item_name">{{L['采购公告']}}</div>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="user_module_banner" v-if="rightBanner.data.advImage" @click="handleGoByBanner(rightBanner.data)">
            <img v-if="curLang == 'zh'" :src="'https://image.caacmarket.com/' + rightBanner.data.advImage" alt=""/>
            <img v-else :src="rightBanner.data.advImageEn" alt=""/>
          </div>
        </div>
      </div>
      <!--      <div class="service_list">-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">机票火车票</div>-->
      <!--            <div class="service_desc">购票直通车</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/jphcp@2x.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">美食/航食</div>-->
      <!--            <div class="service_desc">美食配送</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/mshs@2x.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">酒店/民宿</div>-->
      <!--            <div class="service_desc">高端舒适</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/jdms@2x.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">旅游/景点</div>-->
      <!--            <div class="service_desc">当季热门景点</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/lyjd@2x.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">出行/包机</div>-->
      <!--            <div class="service_desc">一价全包出行便捷</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/cxbj@2x.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">飞行教育基地</div>-->
      <!--            <div class="service_desc">热气球直升机跳伞</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/fjjy@2x.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">飞机模型</div>-->
      <!--            <div class="service_desc">真机模型</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/fjmx@2x.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">文创商品</div>-->
      <!--            <div class="service_desc">民航专供</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/wcsp@2x.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">机场店铺</div>-->
      <!--            <div class="service_desc">候机打卡</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/jcdp@2x.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="service" @click="handleGoService('')">-->
      <!--          <div class="service_info">-->
      <!--            <div class="service_name">机场免税店</div>-->
      <!--            <div class="service_desc">免税驿站</div>-->
      <!--          </div>-->
      <!--          <img class="service_img" src="../../assets/index/jcmsd@2x.png" alt="" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="box_part box_brand" v-if="brandData.data&&brandData.data.length">
        <div class="box_title">
          <div class="title">
            <span class="name">{{ L['品牌旗舰店'] }}</span>
            <span class="desc">{{ L['质量保证，场景打造'] }}</span>
          </div>
        </div>
        <div class="brand_list">
          <div class="brand_item" v-for="(item, idx) in brandData.data" :key="idx" @click="handleGoByBanner(item)">
            <img v-if="curLang == 'zh'" :src="'https://image.caacmarket.com/' + item.advImage" alt=""/>
            <img v-else :src="item.advImageEn" alt=""/>
          </div>
        </div>
      </div>
      <div class="middle_banner" v-if="middleBanner.data.advImage" @click="handleGoByBanner(middleBanner.data)">
        <img v-if="curLang == 'zh'" :src="'https://image.caacmarket.com/' + middleBanner.data.advImage" alt=""/>
        <img v-else :src="middleBanner.data.advImageEn" alt=""/>
      </div>
      <div class="box_theme" v-if="themeData.data&&themeData.data.length">
        <div class="box_special" v-for="(item, idx) in themeData.data" :key="idx">
          <div class="left_name">
            <img v-if="curLang == 'zh'" :src="item.themeImagePath" alt=""/>
            <img v-else :src="item.themeImageEnPath" alt=""/>
          </div>
          <div class="right_content">
            <div class="box_category">
              <div class="name">
                <span>{{ L['选择类目'] }}：</span>
              </div>
              <div class="category_list">
                <div class="category_item"
                     :class="{active: chooseThemeData[item.themeId] == themeCategory.themeCategoryId}"
                     @click="handleChangeCategory(item.themeId, themeCategory.themeCategoryId)"
                     v-for="themeCategory in item.categoryList" :key="themeCategory.themeCategoryId">
                  <span v-if="curLang == 'zh'">{{ themeCategory.themeCategoryName }}</span>
                  <span v-else>{{ themeCategory.themeCategoryNameEn }}</span>
                </div>
              </div>
            </div>
            <div class="product_list">
              <swiper-com :item="item.productList"></swiper-com>
            </div>
          </div>
        </div>
      </div>
      <div class="box_part box_supplier" v-if="supplierList.data&&supplierList.data.length">
        <div class="box_title">
          <div class="title">
            <span class="name">{{ L['精选商家'] }}</span>
            <span class="desc">{{ L['精选商家，优质货源'] }}</span>
          </div>
          <div class="more" @click="handleGoMoreStore">
            <span>{{ L['查看更多'] }}</span>
            <i class="iconfont icon-gengduo1"></i>
          </div>
        </div>
        <div class="supplier_list">
          <div class="supplier_item" v-for="(item, idx) in supplierList.data" :key="idx" @click="handleGoStore(item)">
            <div class="supplier_name" v-if="curLang == 'zh'">{{ item.storeName }}</div>
            <div class="supplier_name" v-else>{{ item.storeNameEn }}</div>
            <div class="supplier_main" :title="curLang == 'zh'?item.mainBusiness:item.mainBusinessEn">
              {{ L['主营'] }}：{{ curLang == 'zh' ? item.mainBusiness : item.mainBusinessEn }}
            </div>
            <div class="product_li">
              <div class="product_item" v-show="index < 2" @click.stop="handleGoDetail(product)"
                   v-for="(product, index) in item.goodsListVOList" :key="index">
                <img :src="product.goodsImage" alt=""/>
                <div class="product_info">
                  <div>
                    <div class="product_name">{{ curLang == 'zh' ? product.goodsName : product.goodsNameEn }}</div>
                    <div class="product_price">
                      ￥{{ product.goodsPrice ? Number(product.goodsPrice).toFixed(2) : '0.00' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="supplier_info">-->
            <!--              <div class="store_info">-->
            <!--                <img src="../../assets/index/shangpin@2x.png" alt="" />-->
            <!--                <div class="product_num">-->
            <!--                  <div class="val">{{ item.hotGoodsNumber }}</div>-->
            <!--                  <div class="key">{{L['商品数量']}}</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="line"></div>-->
            <!--              <div class="service_info">-->
            <!--                <img src="../../assets/index/fuwu@2x.png" alt="" />-->
            <!--                <div class="service_static">-->
            <!--                  <div class="item">-->
            <!--                    <div class="val">{{ item.deliverScore }}</div>-->
            <!--                    <div class="key">{{L['物流']}}</div>-->
            <!--                  </div>-->
            <!--                  <div class="item">-->
            <!--                    <div class="val">{{ item.serviceScore }}</div>-->
            <!--                    <div class="key">{{L['服务']}}</div>-->
            <!--                  </div>-->
            <!--                  <div class="item">-->
            <!--                    <div class="val">{{ item.serviceScore }}</div>-->
            <!--                    <div class="key">{{L['质量']}}</div>-->
            <!--                  </div>-->
            <!--                  <div class="item">-->
            <!--                    <div class="val">{{ item.followNumber }}</div>-->
            <!--&lt;!&ndash;                    <div class="key">价格</div>&ndash;&gt;-->
            <!--                    <div class="key">{{L['收藏']}}</div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="box_like" v-if="goodsList.data.length">
        <div class="title">
          <img class="left" src="../../assets/index/icon_title.png" alt=""/>
          <span>{{ L['猜你喜欢'] }}</span>
          <img class="right" src="../../assets/index/icon_title.png" alt=""/>
        </div>
        <div class="like_list">
          <div class="like_item" v-for="(item, idx) in goodsList.data" :key="idx" @click="handleGoDetail(item)">
            <div class="img_box">
              <img :src="item.goodsImage" alt=""/>
            </div>
            <div class="like_info">
              <div class="like_other">
                <div class="like_price">
                  ￥<span class="price">{{ Number(item.goodsPrice).toFixed(2) }}</span>
                  <!--                  <span class="unit">/{{L['件']}}</span>-->
                </div>
                <div class="num">{{ L['销量'] }}：{{ formatSales(item.saleNum, 0) }}</div>
              </div>
              <div class="like_name text-overflow-2">{{ item.goodsName }}</div>
              <div class="attr-box">
                <p v-if="item.partCode">{{ L['件号/商品号'] }}:{{ item.partCode }}</p>
                <p>{{ L['库存地'] }}:{{ item.cityNames }}</p>
                <p>{{ L['库存数'] }}:{{ item.productStock }}</p>
              </div>
              <div class="store">
                <img src="../../assets/store/STORE.png" alt=""/>
                <p>{{ item.storeName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <SldDiy v-if="decorateData.data.length" :decorateData="decorateData" @adv19="handleAdv19" />-->
    <OpenScreen @closeScreen="closeScreen" v-if="show_open_screen" :openScreenData="openScreenData.data"></OpenScreen>
    <chooseSpecification :dialogVisible="specificationShow" :type="goType" :item="goodsDetail" @close="specificationShow = false"></chooseSpecification>
    <!-- 空页面 start-->
    <!--    <SldCommonEmpty v-if="!firstLoading&&!decorateData.data.length" totalHeight="900" paddingTop="250"-->
    <!--      tip="我们正在努力装修中，敬请期待～" />-->
    <!-- 空页面 end-->
  </div>
</template>
<script>
import {reactive, getCurrentInstance, ref, onMounted, computed} from "vue";
import {useRouter, useRoute} from 'vue-router';
import {useStore} from "vuex";
// import SldDiy from "../../components/SldDiy";
import OpenScreen from "../../components/OpenScreen";
import chooseSpecification from "@/views/home/components/chooseSpecification";
// import SldCommonEmpty from "../../components/SldCommonEmpty";
// import { getQueryVariable } from '@/utils/common.js'
import {formatSales} from "@/utils/common";
import swiperCom from "@/views/home/components/swiperCom";
import topBanner from "@/views/home/components/topBanner";
import {goOtherApp} from "@/utils/goOtherApp";

export default {
  name: "index",
  components: {
    // SldDiy,
    swiperCom,
    OpenScreen,
    chooseSpecification,
    topBanner,
    // SldCommonEmpty
  },
  setup() {
    const {proxy} = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const router = useRouter()
    const route = useRoute()
    const adv_21_right_icon = require("../../assets/decorate/right_more_icon.png");
    const adv_24_let_default_pic = require("../../assets/decorate/adv_24_let_default_pic.png");
    const decorateData = reactive({data: []}); //装修数据
    const openScreenData = reactive({data: {}}); //开屏数据
    const goType = reactive({data:''});
    const goodsDetail = reactive({data:{}});
    const specificationShow = ref(false);
    const brandData = reactive({data: []}); //品牌馆
    const supplierList = reactive({data: []}); //供应商列表
    const goodsList = reactive({data: []}); //商品列表
    const topBanner = reactive({data: []}); //首页顶部banner
    const rightBanner = reactive({data: {}}); //首页右侧banner
    const middleBanner = reactive({data: {}}); //首页中部banner
    const themeData = reactive({data: []}); //主题列表
    const chooseThemeData = reactive({}); //主题列表
    const firstLoading = ref(true); //是否第一次加载
    const show_open_screen = ref(false);
    const themeLoaded = ref(false);
    const store = useStore();
    const curLang = computed(() => store.state.languageType);
    const memberInfo = computed(() => store.state.memberInfo);
    // const internalInstance = getCurrentInstance()

    const handleGoByBanner = (item) => {
      proxy.$diyNavGo(JSON.parse(item.advUrl.replace(/&quot;/g, "\"")));
    }

    const handleGoPath = (path) => {
      router.push({
        path: path
      })
    };

    const goToPage = (url) => {
      window.open(url);
    }

    const goJoin = () => {
      router.push({
        path: `/join`,
      });
    }

    // 去工作台
    const goWithLogin = (path) => {
      // console.log(memberInfo.value.memberId)
      if (memberInfo.value.memberId) {
        goOtherApp({
          type: path
        });
      } else {
        router.push({
          path: `/login`,
        });
      }
    };

    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };

    const handleChangeCategory = (themeId, themeCategoryId) => {
      chooseThemeData[themeId] = themeCategoryId
      getThemeProductList(themeId, themeCategoryId);
    };

    const getInitData = () => {
      let param = {}
      if (route.query.type == 'view' && route.query.id) param.decoId = route.query.id
      proxy.$get("v3/system/front/pcDeco/index", param).then(res => {
        firstLoading.value = false;
        if (res.state == 200) {
          // console.log(123, res.data)

          decorateData.data = res.data.data ? JSON.parse(res.data.data.replace(/&quot;/g, "\"")) : [];
          decorateData.data.map(item => {
            item.json_data = item.json
            if (item.json_data && item.json_data.type == 'adv_19') {
              item.json_data.data.map(child => {
                child.cur_tab = 0;
              })
            }
          });
        }
      });
    };

    // 首页顶部banner
    const getIndexTopBanner = () => {
      let param = {
        advPlaceId: '2',
        state: 1,
        pageSize: 9999
      }
      proxy.$get("v3/system/front/adv/list", param).then(res => {
        if (res.state == 200) {
          // console.log('getIndexTopBanner', res.data)
          topBanner.data = res.data.list || [];
        }
      });
    };

    const getIndexRightBanner = () => {
      let param = {
        advPlaceId: '7',
        state: 1,
        pageSize: 1
      }
      proxy.$get("v3/system/front/adv/list", param).then(res => {
        if (res.state == 200) {
          // console.log('getIndexTopBanner', res.data)
          rightBanner.data = res.data.list[0] || {};
        }
      });
    };

    const getIndexMiddleBanner = () => {
      let param = {
        advPlaceId: '5',
        state: 1,
        pageSize: 1
      }
      proxy.$get("v3/system/front/adv/list", param).then(res => {
        if (res.state == 200) {
          // console.log('getIndexMiddleBanner', res.data)
          middleBanner.data = res.data.list[0] || {};
        }
      });
    };

    // 获取品牌馆列表
    const getBrandList = () => {
      let param = {
        advPlaceId: '3',
        pageSize: 4
      }
      proxy.$get("v3/system/front/adv/list", param).then(res => {
        if (res.state == 200) {
          // console.log('getBrandList', res.data)
          brandData.data = res.data.list;
        }
      });
    };

    const getThemeList = () => {
      themeLoaded.value = false;
      let param = {
        pageSize: 9999,
        state: 1
      }
      proxy.$get("v3/goods/front/themegoods/themelist", param).then(res => {
        if (res.state == 200) {
          // console.log('getThemeList', res.data)
          res.data.list.map(item => {
            item.categoryList = [];
            item.productList = [];
            getThemeCategoryList(item);
          })
          themeData.data = res.data.list;
          // console.log('getThemeList', themeData.data)
        }
      });
    };

    const getThemeCategoryList = (item) => {
      let param = {
        themeId: item.themeId,
        pageSize: 9999
      }
      proxy.$get("v3/goods/front/themegoods/categorylist", param).then(res => {
        if (res.state == 200) {
          // console.log('getThemeList', res.data)
          let obj = {
            themeId: res.data.list[0].themeId,
            themeCategoryId: '',
            themeCategoryName: "全部",
            themeCategoryNameEn: "all"
          }
          item.categoryList = [obj, ...res.data.list];
          getThemeProductList(item.themeId, item.categoryList[0].themeCategoryId)
        }
      });
    };

    const getThemeProductList = (themeId, themeCategoryId) => {
      if (!chooseThemeData[themeId]) {
        chooseThemeData[themeId] = themeCategoryId
      }
      let param = {
        themeId: themeId,
        // pageSize: 16
      }
      if (themeCategoryId != '') {
        param.themeCategoryId = themeCategoryId;
      }
      proxy.$get("v3/goods/front/themegoods/goodslist", param).then(res => {
        if (res.state == 200) {
          // console.log('getThemeProductList', res.data)
          themeData.data.map(item => {
            if (item.themeId == themeId) {
              let newList = [];
              res.data.list.map((item, idx) => {
                if (idx % 8 == 0) {
                  let arr = [];
                  arr.push(item);
                  newList.push(arr);
                } else {
                  newList[newList.length - 1].push(item);
                }
              })
              // console.log(newList)
              item.productList = newList;
            }
          })
          //操作数据后更新视图
          // internalInstance.ctx.$forceUpdate()
          themeLoaded.value = true;
        }
      });
    };

    const handleGoService = (path) => {
      // console.log(path)
    };
    // 供应商
    const getSupplierList = () => {
      proxy.$get("v3/seller/front/store/getRecommendList").then(res => {
        // console.log('getSupplierList', res)
        if (res.state == 200) {
          supplierList.data = res.data.filter(item => item.goodsListVOList.length > 0);
          // supplierList.data = res.data;
        }
      });
    };
    // 猜你喜欢
    const getGoodsList = () => {
      let opt = {
        pageSize: 15,
        sort: 1,
        store: 1
      }
      proxy.$get("v3/goods/front/goods/goodsList", opt).then(res => {
        console.log(res,'goodlist')
        if (res.state == 200) {
          res.data.list.map(item => {
            item.num = 1;
          })
          goodsList.data = res.data.list
        }
      });
    };
    const handleGoMoreStore = () => {
      router.push({
        path: '/store/list'
      })
    };
    const handleGoStore = (item) => {
      router.push({
        path: '/store/goods',
        query: {
          vid: item.storeId
        }
      })
    };
    const handleGoDetail = (item) => {
      router.push({
        path: '/goods/detail',
        query: {
          productId: item.defaultProductId
        }
      })
    };
    const handleOpenSpecification = (item, type) => {
      goType.data = type;
      goodsDetail.data = item;
      specificationShow.value = true;
      document.body.style.overflow = "hidden";
    };
    const getOpenScreen = () => {
      proxy.$get("v3/system/front/deco/firstAdv").then(res => {
        if (res.state == 200) {
          openScreenData.data = JSON.parse(res.data.data.replace(/&quot;/g, "\""));
          if (openScreenData.data.imgUrl && openScreenData.data.show_switch && !route.query.type) {
            if (JSON.stringify(store.state.openScreenInfo) == "{}") {
              openScreenData.data.time = new Date().getTime()
              store.commit("updateOpenScreenInfo", openScreenData.data);
              show_open_screen.value = true;
              document.body.style.overflow = "hidden";
            } else if (
                store.state.openScreenInfo.show_radio_sele == openScreenData.data.show_radio_sele
            ) {
              if (store.state.openScreenInfo.show_radio_sele == "one") {
                if (openScreenData.data.time - new Date().getTime() > 86400) {
                  openScreenData.data.time = new Date().getTime()
                  store.commit("updateOpenScreenInfo", openScreenData.data);
                  show_open_screen.value = true;
                  document.body.style.overflow = "hidden";
                }
              } else if (store.state.openScreenInfo.show_radio_sele == "more") {
                store.commit("updateOpenScreenInfo", openScreenData.data);
                show_open_screen.value = true;
                document.body.style.overflow = "hidden";
              }
            } else {
              openScreenData.data.time = new Date().getTime()
              store.commit("updateOpenScreenInfo", openScreenData.data);
              show_open_screen.value = true;
              document.body.style.overflow = "hidden";
            }
          }
        }
      });
    };
    const closeScreen = () => {
      show_open_screen.value = false;
      document.body.style.overflow = "";
    };
    router.beforeEach((to, from, next) => {
      document.body.style.overflow = "";
      next()
    })
    getIndexTopBanner();
    getIndexRightBanner();
    getIndexMiddleBanner();
    getSupplierList();
    getGoodsList();
    getOpenScreen();
    getInitData();
    getBrandList();
    getThemeList();
    const handleAdv19 = (data) => {
      let tmp_data = decorateData.data.filter(item => item.dataId == data.dataId)[0];
      tmp_data.json_data.data[data.left_or_right_index]['cur_tab'] = data.tab_index;
    }

    onMounted(() => {
      // proxy.$loadingInstance.close();
    })

    return {
      L,
      adv_21_right_icon,
      adv_24_let_default_pic,
      decorateData,
      brandData,
      firstLoading,
      show_open_screen,
      openScreenData,
      supplierList,
      goodsList,
      goType,
      goodsDetail,
      specificationShow,
      topBanner,
      rightBanner,
      middleBanner,
      themeData,
      curLang,
      chooseThemeData,
      themeLoaded,
      memberInfo,
      goToPage,
      goWithLogin,
      handleGoMoreStore,
      handleGoStore,
      handleGoDetail,
      handleOpenSpecification,
      handleGoService,
      handleAdv19,
      closeScreen,
      onSwiper,
      onSlideChange,
      handleGoPath,
      handleChangeCategory,
      handleGoByBanner,
      formatSales,
    };
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/decorate.scss";

.index {
  min-height: 500px;
  background: #F7F7F7;
  padding-bottom: 10px;

  .banner {
    width: 100%;
    height: 470px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .index_lay {
    width: $min-home-width;
    margin: -470px auto 0;
    padding-bottom: 40px;

    .top_info {
      display: flex;
      justify-content: flex-end;
      height: 470px;
      //z-index: 999;

      .box_user_info {
        position: relative;
        width: 300px;
        height: 408px;
        background: #FFFFFF;
        border-radius: 6px;
        margin-top: 20px;
        padding-top: 20px;
        z-index: 999;

        .user_info {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 20px;
          margin: 0 15px;
          border-bottom: 1px solid #E6E6E6;

          .img_avatar {
            width: 45px;
            height: 45px;
            border-radius: 45px;
          }

          .desc {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #303030;
            line-height: 20px;
            margin-top: 10px;
          }

          .box-btn {
            display: flex;
            margin-top: 14px;

            .btn-login {
              width: 80px;
              height: 30px;
              border: 1px solid $colorMain;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 400;
              color: $colorMain;
              line-height: 30px;
              text-align: center;
              cursor: pointer;
            }

            .btn-workspace {
              width: 120px;
              height: 30px;
              border: 1px solid $colorMain;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 400;
              color: $colorMain;
              line-height: 30px;
              text-align: center;
              margin-left: 6px;
              cursor: pointer;
            }

            .btn-center {
              width: 80px;
              height: 30px;
              border: 1px solid $colorMain;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 400;
              background: $colorMain;
              color: #fff;
              line-height: 30px;
              text-align: center;
              margin-left: 32px;
              cursor: pointer;
            }
          }
        }

        .user_module {
          padding: 7px 15px 10px;

          .more {
            position: relative;
            padding-left: 7px;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 4px;
              width: 2px;
              height: 12px;
              background: $colorMain;
            }

            span {
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #303030;
              line-height: 20px;
            }
          }

          .module_list {
            display: flex;
            flex-wrap: wrap;

            .item {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 30%;
              margin-left: 5%;
              margin-top: 20px;
              cursor: pointer;

              &:nth-child(3n + 1) {
                margin-left: 0;
              }

              .item_icon {
                width: 20px;
                height: 20px;
              }

              .item_name {
                height: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #303030;
                line-height: 17px;
                margin-top: 5px;
              }
            }
          }
        }

        .user_module_banner {
          width: 280px;
          //height: 66px;
          height: 132px;
          margin: 8px 10px 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .service_list {
      display: flex;
      flex-wrap: wrap;

      .service {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 220px;
        height: 94px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 0 25px 0 15px;
        margin-top: 20px;
        margin-left: 15px;
        cursor: pointer;

        &:nth-child(5n + 1) {
          margin-left: 0;
        }

        .service_info {
          .service_name {
            height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: #545252;
            line-height: 22px;
          }

          .service_desc {
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #9F9F9F;
            line-height: 17px;
            margin-top: 6px;
          }
        }

        .service_img {
          width: 60px;
          height: 60px;
        }
      }
    }

    .box_part {
      padding-top: 40px;

      .box_title {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 20px;

        .title {
          display: flex;
          align-items: flex-end;

          .name {
            height: 42px;
            font-size: 30px;
            font-weight: 500;
            color: #404040;
            line-height: 42px;
          }

          .desc {
            height: 24px;
            font-size: 17px;
            font-weight: 400;
            color: #9F9F9F;
            line-height: 24px;
            padding-bottom: 4px;
            margin-left: 22px;
          }
        }

        .more {
          display: flex;
          align-items: center;
          height: 21px;
          font-size: 15px;
          font-weight: 400;
          color: #9E9E9E;
          line-height: 21px;
          cursor: pointer;
        }
      }
    }

    .box_brand {
      .brand_list {
        display: flex;

        .brand_item {
          width: 290px;
          height: 290px;
          cursor: pointer;

          & + .brand_item {
            margin-left: 15px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .middle_banner {
      margin-top: 10px;

      img {
        width: 100%;
        height: 100px;
        object-fit: cover;
      }
    }

    .box_special {
      display: flex;
      margin-top: 10px;
      overflow: hidden;

      .left_name {
        width: 220px;
        height: 670px;
        margin-right: 13px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .name {
          height: 30px;
          font-size: 22px;
          font-weight: 500;
          color: #404040;
          line-height: 30px;
          margin-top: 32px;
          margin-left: 18px;
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 65px;
          height: 24px;
          background: #F8F8F6;
          border-radius: 12px;
          margin-top: 10px;
          margin-left: 18px;
          cursor: pointer;

          span {
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            color: #EAA874;
            line-height: 22px;
          }

          .icon_more {
            width: 5px;
            height: 8px;
            margin-left: 10px;
          }
        }
      }

      .right_content {
        flex: 1;
        display: flex;
        flex-direction: column;

        .box_category {
          display: flex;
          align-items: center;
          height: 50px;
          background: #FFFFFF;
          padding: 15px 12px;
          box-sizing: border-box;

          .name {
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #303030;
            line-height: 20px;
          }

          .category_list {
            flex: 1;
            display: flex;
            align-items: center;

            .category_item {
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #303030;
              line-height: 20px;
              cursor: pointer;

              & + .category_item {
                margin-left: 30px;
              }

              &.active {
                color: #F64B14;
              }
            }
          }
        }

        .product_list {
          flex: 1;
          display: flex;
          overflow: hidden;

          .swiper {
            width: 967px;

            .slide_list {
              display: flex;
              flex-wrap: wrap;

              .slide_list_item {
                width: 234.2px;
                height: 255px;
                padding: 8px;
                background: #FFFFFF;
                margin-left: 10px;
                box-sizing: border-box;
                margin-top: 10px;
                cursor: pointer;

                &:nth-child(4n + 1) {
                  margin-left: 0;
                }

                .img_box {
                  width: 214px;
                  height: 150px;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .like_info {
                  margin-top: 20px;

                  .like_name {
                    height: 36px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #303030;
                    line-height: 18px;
                  }

                  .like_other {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 6px;

                    .like_price {
                      //height: 25px;
                      font-size: 12px;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: $colorD;
                      line-height: 17px;

                      .price {
                        font-size: 18px;
                      }

                      .unit {
                        color: #AEAEAE;
                      }
                    }

                    .num {
                      height: 17px;
                      font-size: 12px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #9E9E9E;
                      line-height: 17px;
                    }
                  }

                }
              }
            }

            .el-carousel__arrow {
              background: url('../../assets/index/zuo@2x.png') center no-repeat;
              background-size: cover;
              width: 20px;
              height: 36px;
              border-radius: initial;

              &.el-carousel__arrow--right {
                background: url('../../assets/index/you@2x.png') center no-repeat;
                background-size: cover;
              }

              i {
                display: none;
              }
            }
          }
        }
      }
    }

    .box_supplier {
      .supplier_list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -20px;

        .supplier_item {
          width: 390px;
          height: 280px;
          background: #FFFFFF;
          padding: 15px;
          box-sizing: border-box;
          margin-top: 10px;
          margin-left: 10px;
          cursor: pointer;
          border: 1px solid transparent;

          &:nth-child(3n + 1) {
            margin-left: 0;
          }

          &:hover {
            border: 1px solid $colorD;
            box-shadow: 0px 2px 6px 0px $hoverBorderColor;

            .supplier_name {
              color: $colorD;
            }
          }

          .supplier_name {
            height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: #303030;
            line-height: 22px;
          }

          .supplier_main {
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #9E9E9E;
            line-height: 17px;
            margin-top: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .product_li {
            display: flex;
            margin-top: 12px;

            .product_item {
              position: relative;
              width: 164px;
              height: 164px;
              border: 1px solid #E3E3E3;
              overflow: hidden;

              & + .product_item {
                margin-left: 16px;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s; //设置动画执行的时间为0.6s
                &:hover {
                  transform: scale(1.3); //设置图片按照比例放大1.3倍
                }
              }

              &:hover {
                .product_info {
                  display: block;
                }
              }

              .product_info {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: #fff5f0 !important;
                z-index: 999;

                > div {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  overflow: hidden;
                  height: 100%;
                }

                .product_name {
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                  width: 126px;
                  line-height: 19px;
                  text-align: center;
                  font-family: MicrosoftYaHei;
                  font-size: 14px;
                  color: #ff5226 !important;
                }

                .product_price {
                  line-height: 19px;
                  text-align: center;
                  font-family: MicrosoftYaHei;
                  font-size: 14px;
                  color: #ff5226 !important;
                }
              }
            }
          }

          .supplier_info {
            display: flex;
            align-items: center;
            justify-content: space-around;
            //width: 344px;
            height: 60px;
            background: #F8F8F8;
            margin-top: 20px;
            padding: 15px 5px;
            box-sizing: border-box;

            .store_info {
              display: flex;
              align-items: center;

              img {
                width: 30px;
                height: 30px;
              }

              .product_num {
                text-align: center;
                margin-left: 8px;

                .val {
                  height: 20px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #303030;
                  line-height: 20px
                }

                .key {
                  height: 17px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #9E9E9E;
                  line-height: 17px;
                  margin-top: 2px;
                }
              }
            }

            .line {
              width: 1px;
              height: 30px;
              background: #EBEBEB;
              margin: 0 5px 0 5px;
            }

            .service_info {
              display: flex;
              align-items: center;

              img {
                width: 30px;
                height: 30px;
              }

              .service_static {
                display: flex;
                align-items: center;

                .item {
                  text-align: center;
                  margin-left: 8px;

                  &:nth-child(1) {
                    margin-left: 8px;
                  }

                  .val {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    color: $colorMain2;
                    line-height: 20px;
                  }

                  .key {
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #9E9E9E;
                    line-height: 17px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .box_like {
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 38px 0 6px;

        img {
          width: 45px;
          height: 38px;
        }

        span {
          height: 42px;
          font-size: 30px;
          font-weight: 500;
          color: #404040;
          line-height: 42px;
          margin: 0 30px;
        }

        .right {
          transform: rotateY(180deg);
        }
      }

      .like_list {
        display: flex;
        flex-wrap: wrap;

        .like_item {
          width: 231.5px;
          //height: 319.5px;
          background: #FFFFFF;
          margin-top: 10px;
          margin-left: 10px;
          border: 1px solid transparent;

          &:nth-child(5n + 1) {
            margin-left: 0;
          }

          &:hover {
            border: 1px solid $colorD;
            box-shadow: 0px 2px 6px 0px $hoverBorderColor;

            .like_info {
              .like_name {
                color: $colorD;
              }
            }
          }

          .img_box {
            width: 100%;
            height: 229.5px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: all 0.3s; //设置动画执行的时间为0.6s
              &:hover {
                transform: scale(1.3); //设置图片按照比例放大1.3倍
              }
            }
          }

          .like_info {
            padding: 10px 8px;

            .like_name {
              height: 37px;
              font-size: 14px;
              //font-weight: 500;
              color: #303030;
              line-height: 18px;
            }

            .like_other {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 6px;

              .like_price {
                height: 25px;
                font-size: 12px;
                font-weight: 500;
                color: $colorD;
                line-height: 17px;

                .price {
                  font-size: 18px;
                }

                .unit {
                  color: #AEAEAE;
                }
              }

              .num {
                height: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #9E9E9E;
                line-height: 17px;
              }
            }
            .attr-box {
              > p {
                font-size: 12px;
                color: #888;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
                margin: 6px 0;
              }
            }
            .box-button {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .el-input-number {
                flex:2;
                margin-right: 6px;
              }
              .el-button{
                flex:1;
                border-color: #409eff;
              }
              .el-button--mini {
                padding: 7px 10px;
              }
              }
            .store {
              display: flex;
              align-items: center;
              margin-top: 10px;
              img{
                width: 16px;
                height: 16px;
              }
              p {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #696969;
                margin: 0 0 0 6px;
              }
            }
          }
        }
      }
    }

  }

}

.skeleton_banner {
  width: 100%;
  height: 470px;
  background: $colorSkeleton;
  margin-bottom: 10px;
}

.skeleton {
  &.adv_04_wrap .floor_title h2 span {
    background: $colorSkeleton;
    display: inline-block;
    width: 200px;
  }

  &.adv_04_wrap .floor_goods .item .wrap .title {
    background: $colorSkeleton;
    width: 100%;

    a {
      color: transparent;
    }
  }

  &.adv_04_wrap .floor_goods .item .wrap .price {
    width: 100%;
    height: 25px;
  }

  &.adv_04_wrap .floor_goods .item .wrap .price span {
    display: inline-block;
    width: 70px;
    height: 100%;
    background: $colorSkeleton;
  }
}
</style>
<style lang="scss">
.product_list {
  .el-carousel__arrow {
    background: url('../../assets/index/zuo@2x.png') center no-repeat;
    background-size: cover;
    width: 20px;
    height: 36px;
    border-radius: initial;

    &.el-carousel__arrow--right {
      background: url('../../assets/index/you@2x.png') center no-repeat;
      background-size: cover;
    }

    i {
      display: none;
    }
  }
}
</style>
