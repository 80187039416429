<template>
  <div class="container">
    <div class="top-nav">
      <NavTopBar />
    </div>
    <div class="logo">
      <NavLogo />
    </div>
    <div class="content">
      <div class="content-box" :class="{'box-register': isRegister == true}">
        <login v-if="isLogin" @goRegister="goRegister"></login>
        <register v-if="isRegister" @goLogin="goLogin"></register>
      </div>
    </div>
  </div>
</template>
<script>
  import NavTopBar from "@/components/NavTopBar";
  import NavLogo from "./components/NavLogo";
  import {getCurrentInstance, onMounted, ref} from "vue";
  import {useRoute} from "vue-router";
  import Login from './components/Login.vue'
  import Register from './components/register'
  export default {

    name: '',
    components: {
      NavTopBar,
      NavLogo,
      Login,
      Register,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const route = useRoute();
      const isLogin = ref(true);
      const isRegister = ref(false);
      const isForgetPassword = ref(false);
      const goLogin = () => {
        isLogin.value = true;
        isRegister.value = false;
      };
      const goRegister = () => {
        isLogin.value = false;
        isRegister.value = true;
      };
      onMounted(() => {
        if (route.query.register) {
          isLogin.value = false;
          isRegister.value = true;
        }
        // proxy.$loadingInstance.close();
      })
      return {
        isLogin,
        isRegister,
        isForgetPassword,
        goLogin,
        goRegister,
      }
    }
  }
</script>
<style lang="scss" scoped>
  .container {
    height: 100vh;
    background-color: #FFFFFF;

    .top-nav {
      height: 32px;
      background: #F7F7F7;
    }

    .logo {
      width: 1200px;
      height: 80px;
      margin: 0 auto;
      background: #FFFFFF;
      box-shadow: 0px 2px 6px 0px rgba(0, 109, 228, 0.04);
      display: flex;
      align-items: center;

      .logo1 {
        height: 44px;
        width: 44px;
      }

      .logo2 {
        width: 235px;
        height: 49px;
        margin-left: 10px;

      }
    }

    .content {
      height: calc(100vh - 32px - 80px);
      background: url(https://private.caacmarket.com/workbench/login/loginBd.png) no-repeat;
      background-size: 100% 100%;
      overflow: hidden;

      .content-box {
        width: 1200px;
        margin: 70px auto 0;
        position: relative;
        &.box-register {
          margin: 30px auto 0;
        }
      }
    }
  }
</style>
