<template>
  <Swiper
      class="swiper my_swiper"
      :autoplay="swiper_options.autoplay"
      :loop="swiper_options.loop"
      :navigation="swiper_options.navigation"
      @swiper="onSwiper"
      @mouseenter="onMouseenter"
      @mouseleave="onMouseleave"
      v-if="item.length > 0"
  >
    <SwiperSlide v-for="(list, idx) in item" :key="idx">
      <div class="slide_list">
        <div class="slide_list_item" v-for="product in list" :key="product.themeRelateId"
             @click="handleGoDetail(product.goods)">
          <div class="img_box">
            <img :src="product.goods.mainImageUrl" alt=""/>
            <!--                        <img v-else :src="'https://image.caacmarket.com/' + product.goods.mainImageEn" alt="" />-->
          </div>
          <div class="like_info">
            <div class="like_name text-overflow-2">{{ product.goods.goodsName }}</div>
<!--            <div v-if="curLang == 'zh'" class="like_name text-overflow-2">{{ product.goods.goodsName }}</div>-->
<!--            <div v-else class="like_name text-overflow-2">{{ product.goods.goodsNameEn }}</div>-->
            <div class="like_other">
              <div v-if="curLang == 'zh'" class="like_price">
                ￥<span class="price">{{ Number(product.goods.goodsPrice).toFixed(2) }}</span>
                <!--                                <span class="unit">/{{L['件']}}</span>-->
              </div>
              <div v-else class="like_price">
                ￥<span class="price">{{ Number(product.goods.goodsPrice).toFixed(2) }}</span>
                <!--                                <span class="unit">/{{L['件']}}</span>-->
              </div>
              <div class="num">
<!--                {{ L['销量'] }}：{{ (Number(product.goods.actualSales) + Number(product.goods.virtualSales)).toFixed(0) }}-->
                {{ L['销量'] }}：{{ formatSales(product.goods.actualSales, product.goods.virtualSales) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>

    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </Swiper>
</template>

<script>

import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, onMounted, computed, watch} from "vue";
import { formatSales } from "@/utils/common";
import SwiperCore, {Autoplay, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";

//swiper样式引入（详见https://swiperjs.com/migration-guide 中关于swiper7的说明）
import "swiper/swiper-bundle.css";

//使用SwiperCore注入才能使用swiper中的额外功能比如分页器
SwiperCore.use([Autoplay, Navigation]);
export default {
  name: "swiperCom",
  props: ["item"],
  components: {
    Swiper,
    SwiperSlide
  },
  setup(props) {
    const {proxy} = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const router = useRouter()
    const store = useStore();
    const curLang = computed(() => store.state.languageType);
    // const modules = [Navigation, Autoplay];
    const mySwiper = reactive({});
    const handleGoDetail = (item) => {
      router.push({
        path: '/goods/detail',
        query: {
          productId: item.defaultProductId || item.productId
        }
      })
    };
    const itemLen = computed(() => props.item.length);
    onMounted(() => {
    })
    // swiper相关配置属性放在swiper_options这个变量里
    const swiper_options = reactive({
      initialSlide: 0,
      autoplay: itemLen.value > 1 ? {
        pauseOnMouseEnter: true,
        disableOnInteraction: false, // 鼠标滑动后继续自动播放
        delay: 3000, // 3秒切换一次
      } : false,
      // autoplay: false,
      speed: 500, //切换过渡速度
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
    watch(itemLen, () => {
      swiper_options.autoplay = itemLen.value > 1 ? {
        pauseOnMouseEnter: true,
        disableOnInteraction: false, // 鼠标滑动后继续自动播放
        delay: 3000, // 3秒切换一次
      } : false
    })
    const onSwiper = (swiper) => {
      console.log(123, swiper)
      mySwiper.value = swiper
      swiper.navigation.$nextEl.css('display', 'none');
      swiper.navigation.$prevEl.css('display', 'none');
    }
    const onMouseenter = () => {
      console.log(mySwiper.value)
      mySwiper.value.navigation.$nextEl.css('display', 'block');
      mySwiper.value.navigation.$prevEl.css('display', 'block');
    }
    const onMouseleave = () => {
      console.log(mySwiper.value)
      mySwiper.value.navigation.$nextEl.css('display', 'none');
      mySwiper.value.navigation.$prevEl.css('display', 'none');
    }
    return {
      L,
      swiper_options,
      curLang,
      itemLen,
      onSwiper,
      onMouseenter,
      onMouseleave,
      handleGoDetail,
      formatSales
    }
  }
}
</script>

<style lang="scss">
.my_swiper {
  width: 967px;

  .slide_list {
    display: flex;
    flex-wrap: wrap;

    .slide_list_item {
      width: 234px;
      height: 300px;
      padding: 8px;
      background: #FFFFFF;
      margin-left: 10px;
      box-sizing: border-box;
      margin-top: 10px;
      cursor: pointer;

      &:nth-child(4n + 1) {
        margin-left: 0;
      }

      .img_box {
        width: 214px;
        height: 195px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s; //设置动画执行的时间为0.6s
          &:hover {
            transform: scale(1.3); //设置图片按照比例放大1.3倍
          }
        }
      }

      .like_info {
        margin-top: 20px;

        .like_name {
          height: 36px;
          font-size: 14px;
          font-weight: 400;
          color: #303030;
          line-height: 18px;
        }

        .like_other {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 6px;

          .like_price {
            //height: 25px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: $colorD;
            line-height: 17px;

            .price {
              font-size: 18px;
            }

            .unit {
              color: #AEAEAE;
            }
          }

          .num {
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9E9E9E;
            line-height: 17px;
          }
        }
      }
    }
  }

  .swiper-button-prev {
    background: url('../../../assets/index/zuo@2x.png') center no-repeat;
    background-size: cover;
    width: 20px;
    height: 36px;
    left: 0;

    &:after {
      content: '';
    }
  }

  .swiper-button-next {
    background: url('../../../assets/index/you@2x.png') center no-repeat;
    background-size: cover;
    width: 20px;
    height: 36px;
    right: 0;

    &:after {
      content: '';
    }
  }
}
</style>
