<template>
  <div class="top_header" :class="{fixed_header: searchBarFixed}">
      <NavTopBar/>
      <SldHomeTopSearch/>
      <NavCatHeader/>
      <div class="bottom_line"></div>
  </div>
  <div class="hidden_header">
      <NavTopBar/>
      <SldHomeTopSearch/>
      <NavCatHeader/>
      <div class="bottom_line"></div>
  </div>
  <router-view v-if="isRouterAlive"></router-view>
  <FooterService/>
  <FooterLink/>
</template>

<script>
  import NavTopBar from "../../components/NavTopBar";
  import SldHomeTopSearch from "../../components/SldHomeTopSearch";
  import NavCatHeader from "../../components/NavCatHeader";
  import FooterService from "../../components/FooterService";
  import FooterLink from "../../components/FooterLink";
  import {ref} from "vue";
  export default {
    name: 'Home',
    components: {
      NavTopBar,
      SldHomeTopSearch,
      NavCatHeader,
      FooterService,
      FooterLink,
    },
    setup() {
      const isRouterAlive = true
        const searchBarFixed = ref(false);
        //滚动事件
        const handleScroll = () => {
            var height = 600;
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > height) {
                searchBarFixed.value = true;
            } else {
                searchBarFixed.value = false;
            }
        };
        window.addEventListener("scroll", handleScroll);
      return { isRouterAlive, searchBarFixed }
    }
  }
</script>
<style lang="scss">
.top_header {
    width: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    &.fixed_header {
        width: 100%;
        position: fixed;
        background-color: #fff;
        left: 0;
        right: 0;
        top: 0;
        z-index: 999;
    }
}
.hidden_header {
    background-color: #fff;
    visibility: hidden;
}
</style>
