<template>
  <Swiper
      class="swiper banner_swiper"
      :autoplay="swiper_options.autoplay"
      :loop="swiper_options.loop"
      :pagination="swiper_options.pagination"
      @swiper="onSwiper"
      @mouseenter="onMouseenter"
      @mouseleave="onMouseleave"
  >
    <SwiperSlide v-for="(item, idx) in list" :key="idx">
      <div class="top_banner" @click="handleGoByBanner(item)">
        <img v-if="curLang == 'zh'" :src="'https://image.caacmarket.com/' + item.advImage" alt="" />
        <img v-else :src="item.advImageEn" alt="" />
      </div>
    </SwiperSlide>
    <div class="swiper-pagination"></div><!--分页器。如果放置在swiper外面，需要自定义样式。-->
  </Swiper>
</template>

<script>

import {useStore} from "vuex";
import {getCurrentInstance, reactive, onMounted, computed} from "vue";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

//swiper样式引入（详见https://swiperjs.com/migration-guide 中关于swiper7的说明）
import "swiper/swiper-bundle.css";

//使用SwiperCore注入才能使用swiper中的额外功能比如分页器
SwiperCore.use([Autoplay, Pagination]);
export default {
  name: "topBanner",
  props: ["list"],
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const store = useStore();
    const curLang = computed(() => store.state.languageType);
    // const modules = [Navigation, Autoplay];
    const mySwiper = reactive({});
    onMounted(() => {
    })
    // swiper相关配置属性放在swiper_options这个变量里
    const swiper_options = reactive({
      // autoplay: {
      //   pauseOnMouseEnter:true,
      //   disableOnInteraction: false, // 鼠标滑动后继续自动播放
      //   delay: 2000, // 4秒切换一次
      // },
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
        clickable :true,
      },
      // autoplay: false,
      speed: 500, //切换过渡速度
      loop: true,
    });
    const onSwiper = (swiper) => {
      // console.log(123, swiper)
      mySwiper.value = swiper
    }
    const onMouseenter = () => {
      // console.log(mySwiper.value)
    }
    const onMouseleave = () => {
      // console.log(mySwiper.value)
    }
    const handleGoByBanner = (item) => {
      proxy.$diyNavGo(JSON.parse(item.advUrl.replace(/&quot;/g,"\"")));
    }
    return {
      L,
      swiper_options,
      curLang,
      onSwiper,
      onMouseenter,
      onMouseleave,
      handleGoByBanner
    }
  }
}
</script>

<style lang="scss">
.banner_swiper {
  .top_banner {
    width: 100%;
    height: 470px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
}
</style>
