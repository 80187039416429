<template>
  <div class="main-register">
    <div class="tips-box" @click="$emit('goLogin')">
      {{L['登录']}}
    </div>
    <div class="box-register">
      <div class="tabs-box">
        <h3 :class="{'active': registerForm.data.type == 2}" @click="handleChange(2)">{{L['手机号注册']}}</h3>
        <h3 :class="{'active': registerForm.data.type == 1}" @click="handleChange(1)">{{L['邮箱注册']}}</h3>
        <div :class="'left' + registerForm.data.type"></div>
      </div>
      <template v-if="registerForm.data.type == 2">
        <div class="box-form">
          <div class="input-box">
            <div class="label">{{L['用户名：']}}</div>
            <div class="input-item">
              <el-input v-model="registerForm.data.userName" :placeholder="L['请输入账号']" clearable></el-input>
              <div class="desc">{{L['用户名是由6-20位字符组合']}}</div>
            </div>
          </div>
          <div class="input-box">
            <div class="label">{{L['手机号：']}}</div>
            <div class="input-item">
              <el-input v-model="registerForm.data.account" :placeholder="L['请输入手机号']" clearable>
                <template #prepend>
                  <el-popover
                    placement="bottom-start"
                    width="440px"
                    trigger="click"
                  >
                    <template #reference>
                      <div class="phone-area">
                        <span>+ {{selectCountry.data.code}}</span>
                        <div class="col-line"></div>
                      </div>
                    </template>
                    <el-tabs v-model="activeName">
                      <el-tab-pane v-for="(item, idx) in viewTabs.data" :key="idx" :label="item.title" :name="item.title">
                        <div class="box-country">
                          <div class="country-item" v-for="country in item.country" :key="country.cn + country.code">
                            <div class="country" @click="clickCountry(country)">
                              <span class="country-cn">{{country.cn}}</span>
                              <span class="country-code">{{country.code}}</span>
                            </div>
                          </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </el-popover>
                </template>
              </el-input>
            </div>
          </div>
          <div class="input-box">
            <div class="label">{{L['验证码：']}}</div>
            <div class="input-item">
              <div class="input-group">
                <el-input v-model="registerForm.data.verifyCode" :placeholder="L['请输入验证码']" clearable></el-input>
                <div class="btn-send" @click="getCode">{{sendMsgStr}}</div>
              </div>
            </div>
          </div>
          <div class="input-box">
            <div class="label">{{L['密码：']}}</div>
            <div class="input-item">
              <el-input v-model="registerForm.data.loginPwd" type="password" :placeholder="L['请输入密码']" show-password clearable></el-input>
              <div class="desc">{{L['密码是由6-20位数字字母符号组合']}}</div>
            </div>
          </div>
          <div class="input-box">
            <div class="label">{{L['确认密码：']}}</div>
            <div class="input-item">
              <el-input v-model="registerForm.data.confirmPwd" type="password" :placeholder="L['请输入确认密码']" show-password clearable></el-input>
            </div>
          </div>
          <div class="input-box">
            <div class="label"></div>
            <div class="input-item">
              <div class="fast-enter">
                <span>{{L['邮箱注册']}}{{L['，']}}</span>
                <span @click="handleChange(1)">{{L['去注册']}}</span>
                <img class="icon" src="@/assets/login/go_register_icon.png" alt="" />
              </div>
            </div>
          </div>
          <div class="input-box">
            <div class="label"></div>
            <div class="input-item">
              <p class="btn" @click="handleRegister">{{L['注册']}}</p>
            </div>
          </div>
          <div class="input-box">
            <div class="label"></div>
            <div class="input-item">
              <div class="bottom">
                <el-checkbox v-model:checked="checked"></el-checkbox>
                <p>
                  <span>{{L['我已阅读并同意']}}</span>
                  <span class="link" @click="handleGoLink(1)">{{L['《航云商城用户协议》']}}</span>
                  <span>{{L['和']}}</span>
                  <span class="link" @click="handleGoLink(2)">{{L['《隐私协议》']}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="registerForm.data.type == 1">
        <div class="box-form">
          <div class="input-box">
            <div class="label">{{L['用户名：']}}</div>
            <div class="input-item">
              <el-input v-model="registerForm.data.userName" :placeholder="L['请输入账号']" clearable></el-input>
              <div class="desc">{{L['用户名是由6-20位字符组合']}}</div>
            </div>
          </div>
          <div class="input-box">
            <div class="label">{{L['邮箱账号：']}}</div>
            <div class="input-item">
              <el-input v-model="registerForm.data.account" :placeholder="L['请输入邮箱账号']" clearable></el-input>
            </div>
          </div>
          <div class="input-box">
            <div class="label">{{L['验证码：']}}</div>
            <div class="input-item">
              <div class="input-group">
                <el-input v-model="registerForm.data.verifyCode" :placeholder="L['请输入验证码']" clearable></el-input>
                <div class="btn-send" @click="getCode">{{sendMsgStr}}</div>
              </div>
            </div>
          </div>
          <div class="input-box">
            <div class="label">{{L['密码：']}}</div>
            <div class="input-item">
              <el-input v-model="registerForm.data.loginPwd" type="password" :placeholder="L['请输入密码']" show-password clearable></el-input>
              <div class="desc">{{L['密码是由6-20位数字字母符号组合']}}</div>
            </div>
          </div>
          <div class="input-box">
            <div class="label">{{L['确认密码：']}}</div>
            <div class="input-item">
              <el-input v-model="registerForm.data.confirmPwd" type="password" :placeholder="L['请输入确认密码']" show-password clearable></el-input>
            </div>
          </div>
          <div class="input-box">
            <div class="label"></div>
            <div class="input-item">
              <div class="fast-enter">
                <span>{{L['手机号注册']}}{{L['，']}}</span>
                <span @click="handleChange(2)">{{L['去注册']}}</span>
                <img class="icon" src="@/assets/login/go_register_icon.png" alt="" />
              </div>
            </div>
          </div>
          <div class="input-box">
            <div class="label"></div>
            <div class="input-item">
              <p class="btn" @click="handleRegister">{{L['注册']}}</p>
            </div>
          </div>
          <div class="input-box">
            <div class="label"></div>
            <div class="input-item">
              <div class="bottom">
                <el-checkbox v-model:checked="checked"></el-checkbox>
                <p>
                  <span>{{L['我已阅读并同意']}}</span>
                  <span class="link" @click="handleGoLink(1)">{{L['《航云商城用户协议》']}}</span>
                  <span>{{L['和']}}</span>
                  <span class="link" @click="handleGoLink(2)">{{L['《隐私协议》']}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {computed, getCurrentInstance, onMounted, reactive, ref} from "vue";
import {ElMessage} from "element-plus";
import {checkEmail, checkPhone, checkMemberName, checkSmsCode, checkPwd, checkEmailCode} from "@/utils/common";
import {useRouter, useRoute} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "register",
  setup(ctx,{emit}) {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const registerForm = reactive({data: {
        type: 2, // 1-邮箱注册，2-手机注册
        account: '',
        loginPwd: '',
        confirmPwd: '',
        source: 1,
        userName: '',
        verifyCode: '',
      }
    });
    const selectCountry = reactive({data: {
        cn: '中国',
        code: '86',
        country: 'CN',
        en: 'China',
        heat: 100,
        py: 'ZHONG GUO DA LU',
      }
    });
    const activeName = ref('热门');
    const checked = ref(true);
    const loading = ref(false);
    const times = ref(60);
    const timer = ref(null);
    const viewTabs = reactive({data: []});
    const curLang = computed(() => store.state.languageType);
    const accessToken = ref('');
    const companyList = reactive({ data: [] });
    const sendMsgStr = computed(() => {
      if (times.value == 60) {
        return L["获取验证码"]
      } else {
        return times.value + L["s后获取"]
      }
    });
    onMounted(() => {
      getCountryPhone();
      // proxy.$loadingInstance.close();
    });

    const handleGoLink = (type) => {
      let routeUrl = router.resolve({
        path: '/agreement',
        query: {
          type: type
        }
      })
      window.open(routeUrl.href, '_blank');
    }
    const handleRegister = () => {
      console.log(registerForm.data)
      if (!loading.value) {
        let opt = Object.assign({}, registerForm.data);
        console.log(opt);
        if (!opt.userName) {
          ElMessage.warning(L['请输入账号']);
          return;
        }
        if (checkMemberName(opt.userName) !== true) {
          ElMessage.warning(L['用户名是由6-20位字符组合']);
          return;
        }
        if (opt.type == 2) {
          if (!opt.account || checkPhone(opt.account) !== true) {
            ElMessage.warning(L['请输入手机号'])
            return
          }
        }else {
          if (!opt.account || checkEmail(opt.account) !== true) {
            ElMessage.warning(L['请输入邮箱账号'])
            return
          }
        }
          if (opt.type == 2) {
              if (checkSmsCode(opt.verifyCode) !== true) {
                  ElMessage.warning(L['请输入验证码'])
                  return
              }
          }else {
              if (checkEmailCode(opt.verifyCode) !== true) {
                  ElMessage.warning(L['请输入验证码'])
                  return
              }
          }
        if (!opt.loginPwd) {
          ElMessage.warning(L['请输入密码']);
          return;
        }
        if (checkPwd(opt.loginPwd) !== true) {
          ElMessage.warning(L['密码是由6-20位数字字母符号组合'])
          return
        }
        if (!opt.confirmPwd) {
          ElMessage.warning(L['请输入确认密码']);
          return;
        }
        if (opt.loginPwd !== opt.confirmPwd) {
          ElMessage.warning(L['密码与确认密码不一致']);
          return;
        }
        if (!checked.value) {
          ElMessage.error('请同意航云商城用户协议和隐私协议')
          return
        }
        loading.value = true;
        proxy.$post('v3/frontLogin/oauth/register', opt, 'json').then(res => {
          loading.value = false;
          if (res.state == 200) {
            ElMessage.success(L['注册成功']);
            setTimeout(() => {
              // emit('goLogin');
              // let redriectUrl = route.query.redriectUrl
              // if (redriectUrl) {
              //   let routePath = router.resolve({
              //     path: '/login',
              //     query: {
              //       redriectUrl: redriectUrl
              //     }
              //   })
              //   window.location = routePath.href;
              // }
              localStorage.setItem("access_token", res.data.access_token);
              localStorage.setItem("refresh_token", res.data.refresh_token);
              localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
              getInfo(res.data.access_token);

            }, 1000);
          }else {
            if (res.msg != '参数错误') {
              ElMessage.success(res.msg);
            }else {
              ElMessage.success(L['注册成功']);
              setTimeout(() => {
                emit('goLogin');
              }, 1000);
            }
          }
        })
      }
    };
    //获取用户信息，并同步信息到vuex
    const getInfo = (token) => {
      proxy.$get("/v3/member/front/member/memberInfoWithAuth").then(res => {
        if (res.state == 200) {
          let companyAuthId = '';
          res.data.companyAuthId = companyAuthId;
          store.commit("updateCompanyAuthId", companyAuthId);
          store.commit("updateMemberInfo", res.data);
          proxy.$getLoginCartListData(); //更新购物车数据
          let redriectUrl = route.query.redriectUrl;
          // return;
          if (redriectUrl) {
            window.location.href = `${redriectUrl}?token=${token}&companyAuthId=${companyAuthId}&language=${curLang.value}`
          }else {
            if (window.history.state.back) {
              router.back();
            } else {
              router.replace({ path: '/index' })
            }
          }
        }
      });
    }
    const handleChange = (type) => {
      registerForm.data = {
        type: type, // 1-邮箱注册，2-手机注册
        account: '',
        loginPwd: '',
        confirmPwd: '',
        source: 1,
        userName: '',
        verifyCode: '',
      };
      if (times.value != 60) {
        times.value = 60;
        clearInterval(timer.value);
      }
    };
    // 选择国家
    const clickCountry = (item) => {
      selectCountry.data = item;
    };
    const getCode = () => {
      if (!registerForm.data.account) {
        ElMessage.error(L['输入手机号/邮件账号'])
        return
      } else {
        if (checkPhone(registerForm.data.account) !== true && checkEmail(registerForm.data.account) !== true) {
          ElMessage.error(L['输入手机号/邮件账号'])
          return
        }
      }
      if (times.value != 60) {
        return
      }
      times.value--
      timer.value = setInterval(() => {
        times.value--
        if (times.value == 0) {
          clearInterval(timer.value)
          times.value = 60
        }
      }, 1000)
      proxy.$get('/v3/msg/front/commons/sendRegisterCode', {
        verifyAddr: registerForm.data.account,
        verifyType: checkEmail(registerForm.data.account) === true ? 1 : 2,
      }).then(res => {
        if (res.state == 200) {
          ElMessage.success('验证码已发送!');
        }else {
          ElMessage.error(res.msg);
        }
      })
    };
    /**
     * 获取国家区号
     */
    const getCountryPhone = () => {
      proxy.$get('/v3/system/front/countryCode/list',{}).then((response) => {
        //  console.log('response is ', response);
        const {data} = response;
        //console.log(response.state);
        if (response.state == 200) {
          //console.log(data);
          filtCountryData(data);
        } else {
          //提示错误
          ElMessage.error(response.msg)
        }
      });
    };
    // 组合国家数据
    const filtCountryData = (data) => {
      const tabs = [
        {
          title: '热门',
          keys: ['hot'],
          country: [],
        },
        {
          title: 'ABCDEF',
          keys: ['A', 'B', 'C', 'D', 'E', 'F'],
          country: [],
        },
        {
          title: 'GHIJ',
          keys: ['G', 'H', 'I', 'J'],
          country: [],
        },
        {
          title: 'KLMN',
          keys: ['K', 'L', 'M', 'N'],
          country: [],
        },
        {
          title: 'PQRSTUVW',
          keys: ['P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'],
          country: [],
        },
        {
          title: 'XYZ',
          keys: ['X', 'Y', 'Z'],
          country: [],
        },
      ];
      for (const tab of tabs) {
        for (const coun of data) {
          //console.log(tab);

          if (tab.keys[0] == 'hot') {
            // console.log(coun);

            if (coun.heat) {
              tab.country.push(coun);
            }
          } else {
            for (const key of tab.keys) {
              // console.log(country.en.substr(0,1));
              // console.log(key);
              if (coun.country.substr(0, 1) == key) {
                //console.log(key);
                tab.country.push(coun);
              }
            }
            // console.log(a);
          }
        }
      }
      //console.log('1');
      //console.log(viewTabs);
      viewTabs.data = tabs;
    }
    return {
      L,
      registerForm,
      selectCountry,
      activeName,
      checked,
      loading,
      timer,
      viewTabs,
      sendMsgStr,
      handleChange,
      clickCountry,
      getCode,
      handleRegister,
      handleGoLink,
    }
  }
}
</script>

<style lang="scss">
.main-register {
  width: 610px;
  min-height: 540px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 15px;
  display: flex;
  padding: 50px 30px;
  .tips-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 78px;
    height: 78px;
    background-image: url('../../../../assets/login/login-bg.png');
    background-size: 78px 78px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 22px;
    padding: 13px 7px 0 0;
    text-align: right;
    cursor: pointer;
  }
  .box-register {
    width: 100%;
    .tabs-box {
      display: flex;
      height: 42px;
      position: relative;
      width: 300px;
      margin: 0 auto;

      >h3 {
        flex: 1;
        font-size: 16px;
        font-weight: 400;
        color: #303030;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
        margin: 0;

        &.active {
          font-size: 16px;
          font-weight: 500;
          color: #006DE4;
          line-height: 40px;
        }
      }

      >div {
        width: 50px;
        height: 2px;
        background-color: #006DE4;
        position: absolute;
        transition: left .2s cubic-bezier(.34, .69, .1, 1);
        bottom: 0;

        &.left2 {
          left: 50px;
        }

        &.left1 {
          left: 200px;
        }
      }
    }
    .box-form {
      width: 445px;
      margin: 22px auto 0;
      .input-box {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        .label {
          font-weight: 500;
          color: #303030;
          line-height: 40px;
          width: 80px;
          text-align: right;
        }
        .input-item {
          flex: 1;
          //.el-input {
          //  height: 40px;
          //}
        }
      }
      .el-form-item__label {
        font-weight: 500;
        color: #303030;
        line-height: 40px;
      }
      .el-input {
        &.el-input-group {
          border-radius: 6px;
          overflow: hidden;
          .el-input-group__prepend {
            background: #F6F6F6;
            border: none;
            padding: 0 0 0 14px;
            .phone-area {
              display: flex;
              align-items: center;
              cursor: pointer;
              span {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #303030;
                line-height: 20px;
              }
              .col-line {
                width: 1px;
                height: 24px;
                background: #E6E6E6;
                margin-left: 7px;
              }
            }
          }
          .el-input__inner {
            border-radius: 0;
          }
        }
        .el-input__inner {
          height: 40px;
          background: #F6F6F6;
          border-radius: 6px;
          line-height: 40px;
          border: none;
        }
      }
      .input-group {
        display: flex;
        .el-input {
          flex: 1;
        }
        .btn-send {
          height: 40px;
          background: #F7F6F6;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #FF4304;
          line-height: 40px;
          text-align: center;
          padding: 0 15px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .desc {
        //height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        margin-top: 2px;
        margin-left: 5px;
      }
      .fast-enter {
        //margin-top: 10px;
        span {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #9F9F9F;
          line-height: 17px;
          & + span {
            color: #006DE4;
            cursor: pointer;
          }
        }
        .icon {
          width: 5px;
          height: 6px;
          margin-left: 3px;
        }
      }
    }
    .btn {
      width: 100%;
      height: 44px;
      padding: 10px 0;
      text-align: center;
      background: #006DE4;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 24px;
      margin: 0 0 10px 0;
      cursor: pointer;
    }
    .bottom {
      height: 18px;
      display: flex;
      align-items: center;

      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin: 0 0 0 10px;

        span {
          color: #303030;
          line-height: 17px;
          &.link {
            color: #006DE4;
            margin: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.box-country {
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  overflow-y: auto;
  .country-item {
    width: 100px;
    margin-bottom: 15px;
    margin-right: 2px;
    cursor: pointer;
    line-height: 17px;
    .country {
      font-size: 12px;
      color: #9f9f9f;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .country-cn {
      font-size: 12px;
      color: #303030;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .country-code {
      font-size: 12px;
      color: #9f9f9f;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
